import { useState, useEffect } from 'react';

export function useSessionStorage(key, initialValue) {
    // Obtener el valor inicial del sessionStorage
    const getStoredValue = () => {
        const item = sessionStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
    };

    // Estado para el valor del sessionStorage
    const [storedValue, setStoredValue] = useState(getStoredValue);

    // Efecto para escuchar los cambios en sessionStorage
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.storageArea === sessionStorage && event.key === key) {
                setStoredValue(JSON.parse(event.newValue));
            }
        };

        window.addEventListener('storage', handleStorageChange);

        // Limpiar el listener cuando el componente se desmonte
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [key]);

    // Función para actualizar el sessionStorage y el estado
    const setValue = (value) => {
        setStoredValue(value);
        sessionStorage.setItem(key, JSON.stringify(value));
    };

    return [storedValue, setValue];
}
