import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Login from "./Login";
import { Link, useNavigate, Navigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios"; // npm i axios
/*Imagenes*/

import imgLogo from "../../src/img/logo_new.svg";
import imgBtnObtenCredito from "../../src/img/btn_01.svg";
import imgBtnEncuentraAgente from "../../src/img/btn_02.svg";
import imgBtnIniciaSesion from "../../src/img/btn_04.svg";
import imgBtnHamburguesa from "../../src/img/menu.svg";
import { useLocalStorage } from "../useLocalStorage";

function Header2() {
  // MODIFIED_BY@KH start
  const [showObtenTuModal, setShowObtenTuModal] = useState(false);

  const handleCloseObtenTuModal = () => setShowObtenTuModal(false);
  const handleShowObtenTuModal = () => setShowObtenTuModal(true);
  // MODIFIED_BY@KH end

  const [idusuario, setIdusuario] = useLocalStorage("idusuario", "");
  const [idtipousuario, setIdtipousuario] = useLocalStorage(
    "idtipousuario",
    ""
  );
  const [destipousuario, setDestipousuario] = useLocalStorage(
    "destipousuario",
    ""
  );
  const [idtipocliente, setIdtipocliente] = useLocalStorage(
    "idtipocliente",
    ""
  );
  const [tabIndex, setTabIndex] = useLocalStorage("tabIndex", 0);
  const [shouldRedirect, setShouldRedirect] = useLocalStorage(
    "shouldRedirect",
    false
  );
  const [showpopup, setShowPopup] = useState(false);
  const [mostrarpopup, setMostrarPopup] = useState(false);
  const [usuarioActivo, setusuarioActivo] = useState(() => {
    const UsuActivo = localStorage.getItem("UsuarioActivo");

    if (UsuActivo == null) {
      return;
    }

    const UsuActivoS = JSON.parse(UsuActivo);

    return UsuActivoS || "";
  });

  const navigate = useNavigate();

  const handleRedirect = () => {
    if (window.confirm("¿Desea cerrar sesión?")) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
      window.location.reload(true);
    }
  };

  const handleRedirectLogin = () => {
    const UsuActivo = localStorage.getItem("UsuarioActivo");
          if (localStorage.getItem("edicion") === "1") {
        sessionStorage.removeItem("CoordinatesLatRegAnun");
        sessionStorage.removeItem("CoordinatesLngRegAnun");
        sessionStorage.removeItem("DireccionRegAnun");
        sessionStorage.removeItem("CodAnuncioActual");
        sessionStorage.removeItem("tipotransaccionActual");

        localStorage.removeItem("tipoinmueble");
        localStorage.removeItem("tipotransaccion");
        localStorage.removeItem("descripcion");
        localStorage.removeItem("direccion");
        localStorage.removeItem("latitud");
        localStorage.removeItem("longitud");
        localStorage.removeItem("ciudad");
        localStorage.removeItem("distrito");
        localStorage.removeItem("codigoActual");
        localStorage.removeItem("precio");
        localStorage.removeItem("preciodesde");
        localStorage.removeItem("preciohasta");
        localStorage.removeItem("preciodesde");
        localStorage.removeItem("monedaprecio");
        localStorage.removeItem("terreno");
        localStorage.removeItem("construccion");
        localStorage.removeItem("anioconstruccion");
        localStorage.removeItem("titulo");
        localStorage.removeItem("dormitorios");
        localStorage.removeItem("banios");
        localStorage.removeItem("parqueos");
        localStorage.removeItem("expensas");
        localStorage.removeItem("monedaexpensas");
        localStorage.removeItem("monedagarantia");
        localStorage.removeItem("caractetisticas");
        localStorage.removeItem("nroPisosEdificio");
        localStorage.removeItem("nroDepartamentoPiso");
        localStorage.removeItem("fechaEngrega");
        localStorage.removeItem("estadoproyecto");
        localStorage.removeItem("addressh");
        localStorage.removeItem("address");
        localStorage.removeItem("coordinates");
        localStorage.removeItem("barrio");
        localStorage.removeItem("vdepartamento");
        localStorage.removeItem("vprovincia");
        localStorage.removeItem("vmunicipio");
        localStorage.setItem("edicion", "0");
      }
   if (UsuActivo !== null) {
      navigate("/RegistroAnuncio");
	   }	
   if (UsuActivo === null) {
	  navigate("/RegistroAnuncioSimple"); 
   }
	
  };

  useEffect(() => {
    fetchApi();
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      var x = document.getElementById("popup2");
      var x2 = document.getElementById("popup1");
      if (x2 != null) {
        x2.className = "popupmenu";
        if (x.className !== "popupmenu2") {
          x.className = "popupmenu2";
        }
      }
    };
    // Agregar el evento de scroll cuando el componente se monta
    window.addEventListener("scroll", handleScroll);
    // Limpiar el evento de scroll cuando el componente se desmonta
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function mostraratencion() {
    if (window.innerWidth > 768) {
      setMostrarPopup(true);
      var x = document.getElementById("popup1");
      var x2 = document.getElementById("popup2");

      x2.className = "popupmenu2";

      if (x.className === "popupmenu") {
        x.className += " activo";
      } else {
        x.className = "popupmenu";
      }
    } else {
      navigate("/DetalleUsuario");
    }
  }
  function mostrarMenuFlotante() {
    var x = document.getElementById("popup2");
    var x2 = document.getElementById("popup1");
    x2.className = "popupmenu";

    if (x.className === "popupmenu2" || x.className === "popupmenu2logeado") {
      x.className += " activo";
    } else {
      x.className = "popupmenu2";
    }
  }

  const handleClickOpcion = (opcion) => {
    setTabIndex(opcion);
    navigate("/DetalleUsuario");
    /* switch (opcion) { */
    /* case 0: */
    /*     navigate('/DetalleUsuario'); */
    /*     break; */
    /* case 1: */
    /*     navigate('/DetalleUsuarioI'); */
    /*     break; */
    /* case 2: */
    /*     navigate('/DetalleUsuarioV'); */
    /*     break; */
    /* case 3: */
    /*     navigate('/DetalleUsuarioM'); */
    /*     break; */
    /*  */
    /* default: */
    /*     break; */
    /* } */
  };

  const handleRedirectProyecto = () => {
    sessionStorage.setItem("selectOperacion", 4);

    navigate("/ListAnuncios");
    window.location.reload(true);
  };

  const handleRedirectEncuentraAgente = () => {
    navigate("/ListAgentes");
  };

  window.addEventListener("scroll", function () {
    let header = document.getElementById("principal");
    if (header != null) {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      header.style.opacity = 2 - scrollTop / 500;
    }
  });

  async function fetchApi() {
    let fd = new FormData();
    fd.append("pidusuario", idusuario);
    const res3 = await axios.post(
      "https://merkasa.online/api/ApiObtenerUsuariof.php",
      fd
    );
    if (res3 == null || res3.data == 0 || res3.data == undefined) {
      return;
    } else {
      res3.data.items.map((item) => {
        setIdtipousuario(item.idTipo_Usuario);
        setDestipousuario(item.Destipousuario);
        sessionStorage.setItem("selecttipousuario", item.idTipo_Cliente);
      });
    }
  }

  const OnMostrarpopup = () => {
    if (window.scrollY > 0) {
      setMostrarPopup(!mostrarpopup);
    }
  };

  return (
    <>
      <div id="popup1" className="popupmenu" onMouseLeave={mostraratencion}>
        <ul className="popupmenu_ul">
          <li onClick={(e) => handleClickOpcion(0)}>Mis Avisos</li>
          <li onClick={(e) => handleClickOpcion(1)}>Interesados</li>
          <li onClick={(e) => handleClickOpcion(2)}>Vistos Recientemente</li>
          <li onClick={(e) => handleClickOpcion(3)}>Mis Favoritos</li>
          <li onClick={(e) => handleClickOpcion(4)}>Mi Cuenta</li>
          <li>
            <a style={{ fontSize: "10pt" }} href="#" onClick={handleRedirect}>
              Cerrar Sesión
            </a>
          </li>
        </ul>
      </div>
      <div
        id="popup2"
        onMouseLeave={mostrarMenuFlotante}
        onHide={OnMostrarpopup}
        style={{
          height:
            window.innerWidth <= 1024
              ? !usuarioActivo == ""
                ? "310px"
                : "250px"
              : "200px",
        }}
        className="popupmenu2"
      >
        <ul className="popupmenu2_ul">
          {window.innerWidth <= 1024 ? (
            <li>
              {" "}
              {!usuarioActivo == "" ? (
                <center>
                  <Container
                    style={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      backgroundColor: "#D7D7D7",
                      border: "none",
                      height: "34px",
                      width: "145px",
                      position: "relative",
                    }}
                  >
                    <Row style={{ position: "relative", top: "5px" }}>
                      <Col
                        sm={9}
                        style={{ padding: "0 0 0 0", cursor: "pointer" }}
                        onClick={mostraratencion}
                      >
                        {" "}
                        <Container style={{ padding: "0 0 0 0" }}>
                          <Row style={{ padding: "0 0 0 0" }}>
                            <Col
                              sm={12}
                              style={{
                                padding: "0 0 0 0",
                                top: "-5px",
                                position: "absolute",
                              }}
                            >
                              <a style={{ fontSize: "8pt" }}>
                                <b>
                                  {usuarioActivo.substring(
                                    0,
                                    usuarioActivo.indexOf("@")
                                  )}
                                </b>
                              </a>
                            </Col>
                            <Col
                              sm={12}
                              style={{
                                padding: "0 0 0 0",
                                top: "8px",
                                position: "absolute",
                              }}
                            >
                              <a style={{ fontSize: "8pt", marginTop: "2px" }}>
                                {destipousuario}
                              </a>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col
                        sm={3}
                        style={{
                          padding: "0 0 0 0",
                          left: "-15px",
                          bottom: "-3px",
                          position: "relative",
                          cursor: "pointer",
                        }}
                        onClick={mostraratencion}
                      >
                        <svg
                          width="22"
                          height="22"
                          style={{ float: "right" }}
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.9467 16.9467C22.2539 15.9182 23.208 14.5079 23.6763 12.9119C24.1446 11.316 24.1039 9.61371 23.5597 8.04198C23.0155 6.47025 21.995 5.10721 20.6401 4.1425C19.2852 3.17778 17.6633 2.65936 16 2.65936C14.3367 2.65936 12.7148 3.17778 11.3599 4.1425C10.005 5.10721 8.98446 6.47025 8.44028 8.04198C7.8961 9.61371 7.85535 11.316 8.32368 12.9119C8.79201 14.5079 9.74614 15.9182 11.0533 16.9467C8.81343 17.8441 6.85905 19.3325 5.39852 21.2532C3.93799 23.174 3.02608 25.4551 2.76 27.8534C2.74074 28.0284 2.75615 28.2056 2.80536 28.3748C2.85457 28.5439 2.93662 28.7017 3.04681 28.8391C3.26935 29.1167 3.59304 29.2945 3.94666 29.3334C4.30028 29.3723 4.65488 29.2691 4.93243 29.0465C5.20998 28.824 5.38776 28.5003 5.42666 28.1467C5.71944 25.5403 6.96224 23.1331 8.91762 21.3851C10.873 19.6371 13.4039 18.6707 16.0267 18.6707C18.6495 18.6707 21.1803 19.6371 23.1357 21.3851C25.0911 23.1331 26.3339 25.5403 26.6267 28.1467C26.6629 28.4743 26.8192 28.7769 27.0655 28.996C27.3117 29.2152 27.6304 29.3354 27.96 29.3334H28.1067C28.4562 29.2931 28.7756 29.1164 28.9954 28.8417C29.2152 28.567 29.3175 28.2165 29.28 27.8667C29.0127 25.4616 28.0958 23.1747 26.6278 21.251C25.1598 19.3273 23.1959 17.8393 20.9467 16.9467V16.9467ZM16 16C14.9452 16 13.914 15.6872 13.037 15.1012C12.1599 14.5152 11.4763 13.6822 11.0726 12.7077C10.669 11.7331 10.5634 10.6608 10.7691 9.62621C10.9749 8.59164 11.4829 7.64133 12.2288 6.89545C12.9746 6.14957 13.9249 5.64162 14.9595 5.43584C15.9941 5.23005 17.0664 5.33567 18.041 5.73933C19.0155 6.143 19.8485 6.82659 20.4345 7.70365C21.0205 8.58071 21.3333 9.61186 21.3333 10.6667C21.3333 12.0812 20.7714 13.4377 19.7712 14.4379C18.771 15.4381 17.4145 16 16 16Z"
                            fill="#535353"
                          />
                        </svg>
                      </Col>
                    </Row>
                  </Container>
                </center>
              ) : (
                <Login
                  Textobtn="Inicia Sesión"
                  Variante="primary2"
                  Tipo="1"
                ></Login>
              )}
            </li>
          ) : null}
          <a href="#">
            <li
              // onClick={(e) => handleRedirectCredito()}
              onClick={handleShowObtenTuModal}
            >
              Obtén tu crédito
            </li>
          </a>
          <Link to="/ListAgentes">
            <li>Encuentra tu agente</li>
          </Link>
          <a href="#">
            <li onClick={(e) => handleRedirectProyecto()}>Nuevos proyectos</li>
          </a>
          <Link to="/SobreNosotros">
            <li>Sobre Nosotros</li>
          </Link>
          {/* {usuarioActivo == null ? <li>Login</li> : <li><a style={{fontSize:'10pt'}} href="#" onClick={handleRedirect}>Cerrar Sesión</a></li>}*/}
          {window.innerWidth <= 1024 && !usuarioActivo == "" ? (
            <li>
              <a style={{ fontSize: "10pt" }} href="#" onClick={handleRedirect}>
                Cerrar Sesión
              </a>
            </li>
          ) : null}
        </ul>
      </div>

      <div class="header">
        <div className="divPrincipalHijo1">
          <div>
            <Button
              className="btn btn-primary w-100 botones-header"
              variant="primary5"
              onClick={(e) => handleRedirectProyecto()}
            >
              <b>Nuevos Proyectos</b>
            </Button>
          </div>
          <div>
            <Button
              className="btn btn-primary  w-100 botones-header"
              variant="primary5"
              onClick={(e) => handleRedirectEncuentraAgente()}
            >
              <b>Encuentra tu agente</b>
            </Button>
          </div>
          <div>
            <Button
              className="btn btn-primary  w-100 botones-header"
              variant="primary5"
              // onClick={(e) => handleRedirectCredito()}
              onClick={handleShowObtenTuModal}
            >
              <b>Obtén tu crédito</b>
            </Button>
          </div>
        </div>
        <div className="divPrincipalHijo2">
          <Link to="/">
            <center>
              <img src={imgLogo} className="logoMerkasa" alt="Merkasa"></img>
            </center>
          </Link>
        </div>
        <div className={window.innerWidth > 1024 ? "divPrincipalHijo3" : ""}>
          <div id="header-publica">
            {!usuarioActivo == "" ? (
              <Button
                style={{
                  maxWidth: window.innerWidth < 700 ? "80px" : "",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                  boxShadow: "0 0 0 0",
                }}
                variant="primary4"
                onClick={handleRedirectLogin}
              >
                <b>Publica gratis</b>
              </Button>
            ) : (
				<Button
                style={{
                  maxWidth: window.innerWidth < 700 ? "80px" : "",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                  boxShadow: "0 0 0 0",
                }}
                variant="primary4"
                onClick={handleRedirectLogin}
              >
                <b>Publica gratis</b>
              </Button>
			  			  )
			}
          </div>

          <div
            style={{ display: "flex", margin: "0 0 0 0", position: "relative" }}
          >
            {!usuarioActivo == "" && window.innerWidth > 1024 ? (
              <>
                <center>
                  <Container
                    style={{
                      border: "1px solid black",
                      borderRadius: "10px",
                      backgroundColor: "#F3F3F3",
                      border: "none",
                      height: "40px",
                      width: "120px",
                      position: "relative",
                    }}
                  >
                    <Row>
                      <Col
                        sm={10}
                        style={{ padding: "0 0 0 0", cursor: "pointer" }}
                        onClick={mostraratencion}
                      >
                        <Container style={{ padding: "0 0 0 0" }}>
                          <Row style={{ padding: "0 0 0 0" }}>
                            <Col
                              sm={12}
                              style={{
                                padding: "0 0 0 10px",
                                position: "relative",
                                height: "10px",
                              }}
                            >
                              <a style={{ fontSize: "8pt" }}>
                                <b>
                                  {usuarioActivo.substring(
                                    0,
                                    usuarioActivo.indexOf("@")
                                  )}
                                </b>
                              </a>
                            </Col>
                            <Col
                              sm={12}
                              style={{
                                padding: "0 0 0 0",
                                position: "relative",
                                left: "1px",
                              }}
                            >
                              <a style={{ fontSize: "8pt", marginTop: "2px" }}>
                                {destipousuario}
                              </a>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col
                        sm={2}
                        style={{
                          padding: "0 0 0 0",
                          left: "-10px",
                          bottom: "-3px",
                          position: "relative",
                          cursor: "pointer",
                        }}
                        onClick={mostraratencion}
                      >
                        <svg
                          width="22"
                          height="22"
                          style={{ float: "center" }}
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.9467 16.9467C22.2539 15.9182 23.208 14.5079 23.6763 12.9119C24.1446 11.316 24.1039 9.61371 23.5597 8.04198C23.0155 6.47025 21.995 5.10721 20.6401 4.1425C19.2852 3.17778 17.6633 2.65936 16 2.65936C14.3367 2.65936 12.7148 3.17778 11.3599 4.1425C10.005 5.10721 8.98446 6.47025 8.44028 8.04198C7.8961 9.61371 7.85535 11.316 8.32368 12.9119C8.79201 14.5079 9.74614 15.9182 11.0533 16.9467C8.81343 17.8441 6.85905 19.3325 5.39852 21.2532C3.93799 23.174 3.02608 25.4551 2.76 27.8534C2.74074 28.0284 2.75615 28.2056 2.80536 28.3748C2.85457 28.5439 2.93662 28.7017 3.04681 28.8391C3.26935 29.1167 3.59304 29.2945 3.94666 29.3334C4.30028 29.3723 4.65488 29.2691 4.93243 29.0465C5.20998 28.824 5.38776 28.5003 5.42666 28.1467C5.71944 25.5403 6.96224 23.1331 8.91762 21.3851C10.873 19.6371 13.4039 18.6707 16.0267 18.6707C18.6495 18.6707 21.1803 19.6371 23.1357 21.3851C25.0911 23.1331 26.3339 25.5403 26.6267 28.1467C26.6629 28.4743 26.8192 28.7769 27.0655 28.996C27.3117 29.2152 27.6304 29.3354 27.96 29.3334H28.1067C28.4562 29.2931 28.7756 29.1164 28.9954 28.8417C29.2152 28.567 29.3175 28.2165 29.28 27.8667C29.0127 25.4616 28.0958 23.1747 26.6278 21.251C25.1598 19.3273 23.1959 17.8393 20.9467 16.9467V16.9467ZM16 16C14.9452 16 13.914 15.6872 13.037 15.1012C12.1599 14.5152 11.4763 13.6822 11.0726 12.7077C10.669 11.7331 10.5634 10.6608 10.7691 9.62621C10.9749 8.59164 11.4829 7.64133 12.2288 6.89545C12.9746 6.14957 13.9249 5.64162 14.9595 5.43584C15.9941 5.23005 17.0664 5.33567 18.041 5.73933C19.0155 6.143 19.8485 6.82659 20.4345 7.70365C21.0205 8.58071 21.3333 9.61186 21.3333 10.6667C21.3333 12.0812 20.7714 13.4377 19.7712 14.4379C18.771 15.4381 17.4145 16 16 16Z"
                            fill="#535353"
                          />
                        </svg>
                      </Col>
                    </Row>
                  </Container>
                </center>
              </>
            ) : window.innerWidth > 1024 ? (
              <Login
                Textobtn="Inicia Sesión"
                Variante="primary2"
                Tipo="1"
              ></Login>
            ) : null}
          </div>
          <div id="header-hamburguesa" style={{ position: "relative" }}>
            <img
              src={imgBtnHamburguesa}
              className="divHamburguesa"
              onClick={mostrarMenuFlotante}
            ></img>
          </div>
        </div>
        <div className="divPrincipalHijosHeader"></div>
      </div>

      {/* Obtén tu crédito modal */}
      <Modal
        className="modal-box"
        show={showObtenTuModal}
        onHide={handleCloseObtenTuModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src={imgLogo}
              className="logoMerkasa-modal"
              alt="Merkasa"
            ></img>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-text">Proximamente disfrutaras de esta opción.</p>
        </Modal.Body>
      </Modal>
      {/* Obtén tu crédito modal */}
    </>
  );
}

export default Header2;
