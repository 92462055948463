import React, { useState,useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Layout from "./Components/Layout"; import ListaMapaAnuncios from "./Components/ListaMapaAnuncios";
import {QueryClient,QueryClientProvider} from 'react-query';

function ListAnuncios (){

      const [isLoading, setIsLoading] = useState(false);

      const url = 'https://merkasa.online/api/ApiListarMenu.php'
      const [items, setItems] = useState();

      const url2 = 'https://merkasa.online/api/ApiListarAnuncios.php'
      const [items2, setItems2] = useState();

      const [coordinatesLat,setCoordinstesLat] = useState(() => {

      const LatVal = sessionStorage.getItem("CoordinatesLat");
      const LatValS = JSON.parse(LatVal);

      return LatValS || "";
      });

      const [coordinatesLng,setCoordinstesLng] = useState(() => {
        const LngVal = sessionStorage.getItem("CoordinatesLng");
        const LngValS = JSON.parse(LngVal);
        return LngValS || "";
      });

      const fetchApi = async () => {
 
        const response = await fetch(url)
        const responseJSON = await response.json()
        setItems(responseJSON.items)

        const response2 = await fetch(url2)
        const responseJSON2 = await response2.json()
        setItems2(responseJSON2.items)
        
     }

     /*Secciòn Boton*/
      const [clickedButton, setClickedButton] = useState('');

  
     useEffect(() => {
      //fetchApi();
      /*setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 6000);
      return () => clearTimeout(timer);*/

      },[])

      const queryClient = new QueryClient();
      
      return (
              <ListaMapaAnuncios></ListaMapaAnuncios>
      );
    
  }
  export default ListAnuncios;
