import React, { useState,useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Registro from './Registro';
import { Link,useNavigate } from "react-router-dom";
import { useLocalStorage } from '../useLocalStorage';
import imgCasa from "../img/casita.svg";
import LoadingSpinner from './LoadingSpinner';
import {FaCheckCircle, FaTrash,BiFilterAlt,FaEyeSlash,FaEye } from 'react-icons/fa'; // npm i react-icons
import MensajeModal from './MensajeModal'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

function Login({Textobtn,Variante,Tipo,MostrarButton}) {
	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);
	const [bEyes, setEyes] = useState(false);
	const [correo, setCorreo] = useState('');
	const [correorecuperar, setcorreorecuperar] = useState('');
	const [contraseña, setContraseña] = useState('');
	const [idusuario, setIdusuario] = useLocalStorage('idusuario','')
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const handleClose = () => setShow(false);
	const handleClose2 = () => setShow2(false);
	const handleShow = () => setShow(true);
	const [showObtenTuModal, setShowObtenTuModal] = useState(false);
	const [mensaje,setMensaje] = useState("");


	const handleClickEyes = () => {

		if(bEyes == true)
			{
			setEyes(false);
			}
		else
			{
				setEyes(true);
				}
		}

	const ValidaAcceso = async (correo, contraseña) => {

		if(correo == '' && contraseña == '')
			{
			return;
			}

		await fetch('https://merkasa.online/api/ApiValidaAcceso.php', {
			method: 'POST',
			body: JSON.stringify({
				correo: correo,
				clave: contraseña,
				}),
			headers: {
				'Content-type': 'application/json; charset=UTF-8', }, })
		.then((response) => response.json())
		.then(async (data) => {
			//setPosts((posts) => [data, ...posts]);

			const respuesta = JSON.parse(data);

			if(respuesta == 0 || correo == '' || contraseña == '')
				{
				alert("Usuario y/o contraseña incorrecta, Verifique sus datos.");   
				}else if (respuesta != 0){

					localStorage.setItem("UsuarioActivo",JSON.stringify(correo));
					setIdusuario(respuesta);
					setCorreo('');
					setContraseña('');
					navigate('/');
					window.location.reload(true);
					}


			})
		.catch((err) => {
			console.log(err.message);
			});
		};

	const cambiocontrasenia = async (correo) => {
		setIsLoading(true);

		const data = JSON.stringify({
			correo:correo,
			})

		await fetch('https://merkasa.online/api/ApiSendMailRecuperarContraseña.php', {
			method: 'POST',
			body: data,
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
				},
			})
		.then((response) => response.json())
		.then((data) => {
			//setPosts((posts) => [data, ...posts]);
			const respuesta = data;

			if(respuesta === 0)
				{
				setMensaje("El correo ingresado no existe, verifique sus datos")
				setShow2(false);
				setShowObtenTuModal(true);
				return; 
				}else{

					if(!isNaN(respuesta))
						{
						setMensaje("Revise su correo y siga las instrucciones para continuar el proceso de cambio de contraseña");
						setShow2(false);
						setShowObtenTuModal(true);
						setcorreorecuperar('');
						}
					}
			})
		.catch((err) => {
			console.log(err.message);
			});

		setIsLoading(false);
		}

	const handleSubmit2 = (e) => {

		e.preventDefault();
		ValidaAcceso(correo, contraseña);

		}; 

	const handleSubmitRecuperarContrasenia = (e) =>
		{
			e.preventDefault();
			cambiocontrasenia(correorecuperar);
			}

	const handleCloseModal = () => {
		handleClose();
		};
	const handleClickRecuperar = () => {
		setShow2(true)
		};
	const handleLoginSuccess = (response) => {
		console.log(response);
		}
	const handleLoginFailure = () => {
		console.log('Error en la autenticación con Google');
		};

	return (

	<div style={{overflow:'hidden',width:'100%'}}>
		<div style={{overflow:'hidden',display:'flex',fontWeight:'bold'}}>
			<Button variant={Variante} onClick={handleShow} style={{height:'26px',width:'120px', borderRadius:'10px', textTransform:'capitalize',boxShadow:'0 0 0 0',marginBottom:'3px'}} id='button-login'>
				{Textobtn}
			</Button>
		</div>

		{Tipo == 1 ? 
		<>
			<Modal  size="sm" show={show} onHide={handleClose}>
				<Modal.Header style={{border:'none'}} closeButton >
					<Container>
						<Row>
							<Col sm={2}>
							</Col>
							<Col sm={3}>
							</Col>
							<Col sm={2}>
								<center><Modal.Title style={{fontSize:'15pt',height:'30px'}}><p><b>Login</b></p></Modal.Title></center>
							</Col>
							<Col sm={3}>
							</Col>
							<Col sm={2}>
							</Col>
						</Row>
					</Container>
				</Modal.Header>
				<Modal.Body style={{borderRadius:'5px'}}>
					<br></br>
					<Form onSubmit={handleSubmit2}>
						<Container style={{padding:'0 0 0 0'}}>
							<Row>
								<Col sm={2}>

								</Col>
								<Col sm={8}>
									<Container style={{padding:'0 0 0 0'}}>
										<Row style={{paddingBottom:'20px'}}>
											<Col sm={12}>
												<Form.Group className="sm-3" controlId="exampleForm2.ControlInput1">
													<Form.Label style={{fontSize:'10pt'}}><b>Usuario</b></Form.Label>
													<Form.Control
														type="email"
														placeholder="nombre@dominio.com"
														autoFocus
														value={correo}
														onChange={(e) => setCorreo(e.target.value)}
														style={{fontSize:'9pt',color:'black'}}
													/>
												</Form.Group>
											</Col>
										</Row>
										<Row style={{paddingBottom:'20px'}}>
											<Col sm={12}>
												<Container style={{padding:'0 0 0 0'}}>
													<Row>
														<Col sm={10}>
															<Form.Group className="sm-3" controlId="exampleForm2.ControlInput2">
																<Form.Label style={{fontSize:'10pt'}}><b>Contraseña</b></Form.Label>
																<Form.Control
																	type={bEyes == false ? "password" : "text"}
																	value={contraseña}
																	onChange={(e) => setContraseña(e.target.value)}
																	style={{fontSize:'9pt',color:'black'}}
																/>
															</Form.Group>
														</Col>
														<Col sm={2} style={{padding:'0 0 0 0'}}>
															<Form.Group className="sm-3" controlId="exampleForm2.ControlInput2">
																<Form.Label style={{fontSize:'10pt'}}><b style={{color:'white'}}>Ver</b></Form.Label>
																{bEyes == false ? <><FaEyeSlash onClick={handleClickEyes} /></> : <><FaEye onClick={handleClickEyes} /></>}  
															</Form.Group>

														</Col>
													</Row>
												</Container>
											</Col>
											<Col sm={12}>
												<a onClick={handleClickRecuperar} style={{fontSize:'9pt',float:'left',cursor:'pointer'}}>Recuperar Contraseña</a>
											</Col>
										</Row>
									</Container>

								</Col>
								<Col sm={2}>

								</Col>
							</Row>
						</Container>

						<br></br>
						<Container>
							<Row>
								<Col sm={12}>
									<center>
										<Button variant="primary4" type='submit'>
											<b style={{fontSize:'7pt'}}>Aceptar</b>  
										</Button>
									</center> 
								</Col>
							</Row>
							<Row>
								<Col sm={12} >
									<center>
										<a href='#' onClick={() =>document.getElementById('registro').click()}>Registrarse</a>
									</center> 

								</Col>
							</Row>
						</Container>
					</Form>
					<br></br>
					<div>
						<GoogleOAuthProvider clientId="818637663608-meiealqjfrl7n3fum43b4us9een52cb4.apps.googleusercontent.com">
							<GoogleLogin
								onSuccess={handleLoginSuccess}
								onError={handleLoginFailure}
								text="signin_with"
								cookiePolicy={'single_host_origin'}
								isSignedIn={true}
							/>

					</GoogleOAuthProvider>				
				</div>
			</Modal.Body>
		</Modal>

		<Registro ocultarModal={handleClose}></Registro>

	</> : 
				// Comportamiento para Publica gratis

				<div style={{width:'100%'}}>
					<Modal show={show} onHide={handleClose}>
						<Modal.Header style={{border:'none'}} closeButton>

						</Modal.Header>
						<Modal.Body style={{paddingLeft:'0',paddingRight:'0'}}>

							<Container style={{padding:'0 0 0 0'}}>
								<Row>
									<Col sm={1}>

									</Col>
									<Col sm={10}>
										<Container >
											<Row>
												<Col sm={12}>
													<center><img src={imgCasa} style={{width:'150px'}}></img></center> 
												</Col>
												<Col sm={12}>
													<center><p style={{fontSize:'12pt'}}><b>Bienvenido a</b> <b style={{color:'orangered'}}>Merkasa</b> </p></center>
												</Col>
												<Col sm={12}>
													<center> <p style={{fontSize:'9pt',color:'black'}}><b>Publica de manera gratuita los inmuebles que desees. Además podrás acceder a planes que te ayudarán a llegar a más usuarios.</b></p>
														<p style={{fontSize:'9pt',color:'black'}}><b>Puedes crear tu perfil con los datos mínimos, los cuales puedes completarlos en cualquier momento.</b></p></center>
												</Col>
											</Row>
										</Container>
									</Col>

									<Col sm={1}>
									</Col>
								</Row>
								<Row>
									<Col sm={12}>
										<center>
											<Registro Tipo={2} MostrarButton= {MostrarButton} ></Registro>
										</center> 
									</Col>
								</Row>
							</Container>        
							<br></br>
						</Modal.Body>
					</Modal>
			</div>}
				<Modal  centered={true}  show={show2} onHide={handleClose2}>
					<Modal.Header style={{border:'none'}} closeButton >
						<Container>
							<br></br>
							<Row>
								<Col sm={12}>
									<center><Modal.Title style={{fontSize:'15pt',height:'30px'}}><p><b>Recuperar Contraseña</b></p></Modal.Title></center>
								</Col>
							</Row>
						</Container>
						<br></br>
					</Modal.Header>
					<Modal.Body style={{borderRadius:'5px'}}>
						<br></br>
						<br></br>
						<Form onSubmit={handleSubmitRecuperarContrasenia}>
							<Container style={{padding:'0 0 0 0'}}>
								<Row>
									<Col sm={2}>

									</Col>
									<Col sm={8}>
										<Container style={{padding:'0 0 0 0'}}>
											<Row style={{paddingBottom:'30px'}}>
												<Col sm={12}>
													<Form.Group className="sm-3" controlId="exampleForm2.ControlInput1">
														<Form.Label style={{fontSize:'12pt'}}><b>Ingrese correo electrónico</b></Form.Label>
														<Form.Control
															type="email"
															placeholder="nombre@dominio.com"
															autoFocus
															value={correorecuperar}
															onChange={(e) => setcorreorecuperar(e.target.value)}
															style={{fontSize:'12pt',color:'black'}}
														/>
													</Form.Group>
												</Col>
											</Row>
											<Row style={{paddingBottom:'70px'}}>
												{isLoading ? <LoadingSpinner /> : null}
											</Row>
										</Container>

									</Col>
									<Col sm={2}>

									</Col>
								</Row>
							</Container>

							<br></br>
							<Container>
								<Row>
									<Col sm={12}>
										<center>
											<Button variant="primary4" type='submit'>
												<b style={{fontSize:'7pt'}}>Recuperar</b>  
											</Button>
										</center> 

									</Col>
								</Row>
							</Container>
						</Form>
						<br></br>
					</Modal.Body>
				</Modal>

				<MensajeModal Mensaje={mensaje} Mostrar={showObtenTuModal} setClose={() => setShowObtenTuModal(false)} ></MensajeModal>

			</div>
		);
}
export default Login;
