import React, { useState,useEffect, createElement } from 'react';

var map;
const API_KEY = 'AIzaSyDF09ajXzzNFYGK6tPuWX6yCBzK0DUeSmM';

function Mapadetalleanuncio({Latitud,Longitud,Precio,Direccion,Imagen}){

    const [mapaV, setMapa] =  useState();
    
    useEffect(() => {
        CargarMapa();
        cargarMarker();
      },[])
    
    function CargarMapa()
    {
        if (!window.google) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src =
              `https://maps.googleapis.com/maps/api/js?key=` +
              API_KEY +
              `&libraries=geometry,places`;
            script.id = 'googleMaps';
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
            script.addEventListener('load', (e) => {
              renderMap();
            });
          } else {
            renderMap();
          }
    }

    function renderMap()
    {
        const coords = { lat: -16.489689, lng: -68.11929359999999 };
        const el = document.getElementById('mapaDetalle');
    
        if (el) {
          map = new window.google.maps.Map(el, {
            zoom: 14,
            center: {
              lat: coords.lat,
              lng: coords.lng,
            },
          });
          setMapa(map);
          return map;
        } else {
          return null;
        }
      }

      function cargarMarker()
      {
        let marker,i;

        let infowindow = new window.google.maps.InfoWindow();                     

        marker = new window.google.maps.Marker({
                    map: map,
                    position:  new window.google.maps.LatLng(Latitud, Longitud),
                    icon: "https://merkasa.online/img/MerkasaIcon.svg"
                });

        map.setCenter(marker.getPosition());
		  window.innerWidth > 769 ? 
        infowindow.setContent("<div style='float:left;max-height:100px'><img src='"+Imagen+"' style='height:80px;width:80px;border-radius:10px'></div><div style='float:right; padding: 10px;'><b>Merkasa</b><br/>"+Direccion+"<br/>$"+Precio+"</div>")
		  : infowindow.setContent("<div style='display:flex;'><div style='float:left;'><img src='"+Imagen+"' style='height:80px;width:80px;border-radius:10px'></div><div style='float:right;padding: 0 0 0 10px;font-size:7pt'><b>Merkasa</b><br/>"+Direccion+"<br/>$"+Precio+"</div></div>");

        infowindow.open(map, marker);

        window.google.maps.event.addListener(marker, 'mouseover', (function(marker, i) {
            return function() {

            infowindow.setContent("<div style='display:flex'><div style='float:left;'><img src='"+Imagen+"' style='height:80px;width:80px;border-radius:10px'></div><div style='float:right;padding: 0 0 0 10px;font-size:7pt'><b>Merkasa</b><br/>"+Direccion+"<br/>$"+Precio+"</div></div>");

            infowindow.open(map, marker);
            }
        })(marker, i));
      }

      return (<>

            <div id='mapaDetalle'>
            </div>    

      </>)

}

export default Mapadetalleanuncio;
