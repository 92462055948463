import React, { useState , useEffect, useRef} from 'react';
import {Container, TextField, Button, Grid, Typography, Box, Card, CardMedia, MenuItem, Select, FormControl, InputLabel,styled,Paper, IconButton,Snackbar, Alert } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useForm, Controller } from 'react-hook-form';
import {useLocalStorage} from './useLocalStorage';
import SearchGoogleMapsRegistro from './Components/SearchGoogleMapsRegistro';
import {useSessionStorage} from './useSessionStorage';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Sortable from 'sortablejs';
import MensajeModal from "./Components/MensajeModal";
import { useNavigate } from "react-router-dom";
import axios from 'axios'; 
import LoadingSpinner from './Components/LoadingSpinner';
// Estilo para el área de carga de archivos
const DropzonePaper = styled(Paper)(({ theme }) => ({
	border: '2px dashed #cccccc',
	padding: theme.spacing(2),
	textAlign: 'center',
	cursor: 'pointer',
	marginBottom: theme.spacing(2),
}));

// Estilo para las tarjetas de imagen
const StyledCard = styled(Card)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	position: 'relative',
	width: 200,
	margin: theme.spacing(1),
}));

// Estilo para el botón de eliminar
const DeleteButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	top: 8,
	right: 8,
	backgroundColor: theme.palette.background.paper,
	'&:hover': {
		backgroundColor: theme.palette.error.light,
		},
}));

const tiposInmueble = [
	'Casa',
	'Departamento',
	'Terreno',
	'Oficina',
	'Casa de campo',
	'Edificio',
	'Local Comercial',
	'Industrial',
	'Parqueo',
	'Agricola'
];

const tiposTransaccion = [
	'Venta',
	'Alquiler',
	'Anticretico',
];
const monedas = [
	'Bolivianos',
	'Dolares'
];


function RegistroSimple() {
	const { register, handleSubmit, control, formState: { errors } } = useForm();	
	const [mensaje,setMensaje] = useState("");
	const [showObtenTuModal, setShowObtenTuModal] = useState(false);
	const [tipoInmueble, setTipoInmueble] = useState("");
	const [latlngmunicipio, setlatlngmunicipio] = useLocalStorage('latlngmunicipio', [{"Latitud": "-16.489689", "Longitud": "-68.11929359999999"}]);
	const [dire,setdire] = useSessionStorage('DireccionRegAnun', '');
	const [codigoActual, setCodigoActual] = useLocalStorage('codigoActual', '');
	const [bvisitaPagina, setVisitaPagina] = useLocalStorage('bvisitaPagina', false);
	const [precio, setprecio] = useState("");
	const [images, setImages] = useState([]);
	const [imagesAMostrar, setImagesAMostrar] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [vistobueno,setVistoBueno] = useState(false);

	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		onDrop: (acceptedFiles) => {
			setImages((prevImages) => [
				...prevImages,
				...acceptedFiles.map((file) => {
					return {
						...file,
						file: file,
						preview: URL.createObjectURL(file),
						};
					})
				]);
			setImagesAMostrar((prevImages) => [
				...prevImages,
				...acceptedFiles.map((file) => {
					return {
						...file,
						file: file,
						preview: URL.createObjectURL(file),
						};
					})
				]);



			}
		});

	const navigate = useNavigate();

	function convertirTipoANumero(tipo) {
		if (tipo === "Casa") {
			return 1;
			}
		if (tipo === "Departamento") {
			return 2;
			}
		if (tipo === "Terreno") {
			return 3;
			}
		if (tipo === "Oficina") {
			return 4;
			}
		if (tipo === "Casa de campo") {
			return 5;
			}
		if (tipo === "Edificio") {
			return 6;
			}
		if (tipo === "Local Comercial") {
			return 7;
			}
		if (tipo === "Industrial") {
			return 8;
			}
		if (tipo === "Parqueo") {
			return 9;
			}
		if (tipo === "Agricola") {
			return 10;
			}
		return 0;
		}
	function convertirTransaccionANumero(tipo) {
		if (tipo === "Venta") {
			return 1;
			}
		if (tipo === "Alquiler") {
			return 2;
			}
		if (tipo === "Anticretico") {
			return 3;
			}
		return 0;
		}
	function convertirMonedaASigla(tipo) {
		if (tipo === "Bolivianos") {
			return "BS";
			}
		if (tipo === "Dolares") {
			return "$us";
			}
		return 0;
		}
	const onSubmit = (data) => {
		setIsLoading(true);
		const tipoinmueble = convertirTipoANumero(data.tipoInmueble);
		const tipotransaccion = convertirTransaccionANumero(data.tipoTransaccion);
		const moneda = convertirMonedaASigla(data.moneda);

		const NewDir = localStorage.getItem("address").replace(/"/g, "");
		const NewLat = JSON.parse(sessionStorage.getItem("CoordinatesLatRegAnun"));
		const NewLng = JSON.parse(sessionStorage.getItem("CoordinatesLngRegAnun"));

		RegistroAnuncio(tipoinmueble,tipotransaccion, data.descripcion, data.precio, NewDir, NewLat, NewLng, data.numDormitorios, data.numBanios,moneda,data.celular);

		};

	const RegistroAnuncio = async (tipoinmueble, tipotransaccion, descripcion, precio, direccion, latitud, longitud, dormitorios, banios,moneda,celular) => {

		if (tipoinmueble == 0) {
			setMensaje("Debe seleccionar un Tipo de inmueble");
			setShowObtenTuModal(true);
			return;
			}

		if (tipotransaccion === 0) {
			setMensaje("Debe seleccionar un Tipo de transacción");
			setShowObtenTuModal(true);
			return;
			}
		if (images.length < 1) {
			alert("Para publicar debe subir al menos 1 foto.");
			return;
			}


		const data = JSON.stringify({
			tipoinmueble: tipoinmueble, tipotransaccion: tipotransaccion, descripcion: "", precio: "", direccion: direccion, latitud: latitud, longitud: longitud, ciudad: "", distrito: "", correousuario: "", tipo: 1, pidanuncio: 0, pterreno: "", pconstruccion: 2, panioconstruccion: "", ptitulo: "", pdormitorios: "", pbanios: "", pparqueos: "", expensas: '', fechaentregaproyecto: '', estadoproyecto: 0, nroedixproy: 0, nrodepxproy: 0, vdesdeprecio: 0, vhastaprecio: 0,celular:celular 
			})
		await fetch('https://merkasa.online/api/ApiRegistroAnuncio.php', {
			method: 'POST',
			body: data,
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
				},
			})
		.then((response) => response.json())
		.then(async(data) => {

			const respuesta = data.anuncioid;

			if (respuesta === 0) {
				setMensaje("El Anuncio no fue registrado, intente nuevamente.");
				setShowObtenTuModal(true);
				return;
				} else {

					if (!isNaN(respuesta)) {
						sessionStorage.setItem("CodAnuncioActual", JSON.stringify(respuesta));
						const data2 = JSON.stringify({
							tipoinmueble: 1, tipotransaccion: 1, descripcion: descripcion, precio: precio, direccion: '', latitud: '', longitud: '', ciudad: '', distrito: '', correousuario: '', tipo: 2, pidanuncio: respuesta, pterreno: '', pconstruccion: '', panioconstruccion: '', ptitulo: '', pdormitorios: dormitorios, banios: banios, pparqueos: '', expensas: '', monedaprecio: moneda, monedaexpensas: '', fechaentregaproyecto: '', estadoproyecto: '', nroedixproy: '', nrodepxproy: '', vdesdeprecio: '', vhastaprecio: '', celular:''
							})
						await fetch('https://merkasa.online/api/ApiRegistroAnuncio.php', {
							method: 'POST',
							body: data2,
							headers: {
								'Content-type': 'application/json; charset=UTF-8',
								},
							})
						.then((response) => response.json())
						.then(async() => {
							window.scrollTo(0, document.body.scrollHeight)
							await registrarImagenes(respuesta);
							setCodigoActual(respuesta)
							setVisitaPagina(true);
							setIsLoading(false);
							setVistoBueno(true);
							}
							)
						.catch((err) => {
							setShowObtenTuModal(true);
							setMensaje("Hubo un error al publicar el anuncio, intente nuevamente.");
							setIsLoading(false);
							return;
							});
						};
					setTimeout(() => {
						navigate('/')
						},3000);
					}
			})
		.catch((err) => {
			setShowObtenTuModal(true);
			setMensaje("Hubo un error al publicar el anuncio, intente nuevamente.");
			setIsLoading(false);
			return;
			});
		};

	async function registrarImagenes(CodAnuncio) {
		images.map(async (imagen,index) => {

			const CodAnuncioS = JSON.parse(CodAnuncio);
			const imgFinal = await convertToWebP(imagen.file);

			let fd = new FormData()

			fd.append("pidanuncio", CodAnuncioS)
			fd.append("isportada", index)
			fd.append("imagenbs4", imgFinal)
			await axios.post('https://merkasa.online/api/ApiRegistroAnuncioImgf.php', fd);
			});

		}

	const convertToWebP = (blob) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onloadend = () => {
				const img = new Image();
				img.src = reader.result;

				img.onload = () => {
					const canvas = document.createElement('canvas');
					const ctx = canvas.getContext('2d');

					canvas.width = img.width;
					canvas.height = img.height;
					ctx.drawImage(img, 0, 0);

					// Obtener el Data URL en formato WebP
					const webpDataUrl = canvas.toDataURL('image/webp');
					resolve(webpDataUrl);
					};

				img.onerror = () => {
					reject(new Error('Error al cargar la imagen'));
					};
				};

			reader.onerror = () => {
				reject(new Error('Error al leer el Blob'));
				};

			reader.readAsDataURL(blob);
			});
		}
	const handleTipoInmuebleChange = (event) => {
		setTipoInmueble(event.target.value);
		};
	function formatNumber(number) {

		let newValor = number.replace(/,/g, "");

		return new Intl.NumberFormat("EN-US").format(newValor);
		}
	function soloNumeros(number) {

		let newValor = number.replace(/\D/g, "");


		return newValor;
		}
	const handleDelete = (file) => {
		setImages((prevImages) => prevImages.filter((img) => img !== file));
		setImagesAMostrar((prevImages) => prevImages.filter((img) => img !== file));
		// Limpiar el objeto URL creado
		URL.revokeObjectURL(file.preview);
		};

	const gridRef = useRef(null);



	useEffect(() => {
		if (gridRef.current) {
			new Sortable(gridRef.current, {
				animation: 150,
				ghostClass: 'sortable-ghost',
				onEnd: ({ oldIndex, newIndex }) => {
					setImages(currentImages => {
						// Crear una copia inmutable del array de imágenes
						let updatedImages = structuredClone(currentImages);
						const movedImage = updatedImages.splice(oldIndex, 1)[0];
						updatedImages.splice(newIndex, 0, movedImage);
						return updatedImages;
						});
					}
				});
			}
		}, []);	
	return (
		<Container>
			<Typography variant="h4" gutterBottom> Publicación rápida </Typography>
			<DropzonePaper {...getRootProps({ className: 'dropzone' })}>
				<input {...getInputProps()} />
				<Typography>Arrastra y suelta algunas imágenes aquí, o haz clic para seleccionar imágenes</Typography>
			</DropzonePaper>
			<Grid container spacing={1} ref={gridRef} className='grid-imagenes' >
				{imagesAMostrar.map((image) => (
					<Grid item >
						<StyledCard >
							<Card  sx={{ width: 200, height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<CardMedia
									component="img"
									image={image.preview}
									alt="Dropped"
									sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
								/>
							</Card>
							<DeleteButton onClick={() => handleDelete(image)}>
								<DeleteIcon />
							</DeleteButton>

						</StyledCard>

					</Grid>
				))}
			</Grid>			
			<Box my={4}>
				<Typography variant="h6">Información del inmueble</Typography>
				<br></br>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth>
								<InputLabel>Tipo de inmueble</InputLabel>
								<Controller
									name="tipoInmueble"
									control={control}
									defaultValue=""
									rules={{ required: 'Tipo de inmueble es requerido' }}
									render={({ field }) => (
										<Select
										{...field}
										label="Tipo de inmueble"
										onChange={(e) => {
											handleTipoInmuebleChange(e);
											field.onChange(e); // Mantén la sincronización con react-hook-form
											}}
										>
											{tiposInmueble.map((tipo, index) => (
												<MenuItem key={index} value={tipo}>
													{tipo}
												</MenuItem>
											))}
										</Select>
									)}
								/>
							</FormControl>
							{errors.tipoInmueble && <p>{errors.tipoInmueble.message}</p>}
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth>
								<InputLabel>Tipo de transacción</InputLabel>
								<Controller
									name="tipoTransaccion"
									control={control}
									defaultValue=""
									rules={{ required: 'Tipo de transacción es requerido'}}
									render={({ field }) => (
										<Select {...field} label="Tipo de transacción">
											{tiposTransaccion.map((tipo, index) => (
												<MenuItem key={index} value={tipo}>
													{tipo}
												</MenuItem>
											))}
											onChange={(e) => {
												field.onChange(e); // Mantén la sincronización con react-hook-form
												}}
											</Select>
										)}
									/>
								</FormControl>
								{errors.tipoTransaccion && <p>{errors.tipoTransaccion.message}</p>}
							</Grid>
							<Grid item xs={6} sm={3}>
								<FormControl fullWidth>
									<InputLabel>Moneda</InputLabel>
									<Controller
										name="moneda"
										control={control}
										defaultValue=""
										rules={{ required: 'Moneda es requerido' }}
										render={({ field }) => (
											<Select {...field} label="Moneda">
												{monedas.map((tipo, index) => (
													<MenuItem key={index} value={tipo}>
														{tipo}
													</MenuItem>
												))}
												onChange={(e) => {
													field.onChange(e); // Mantén la sincronización con react-hook-form
													}}
												</Select>
											)}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={6} sm={3}>
									<TextField
										variant="outlined"
										id="outlined-controlled"
										label="Precio"
								type="text"
								fullWidth
								inputProps={{ 
									value: formatNumber(precio),
									inputMode: 'numeric', 
									maxLength: 10 ,
									onChange: (event) => {
										setprecio(soloNumeros(event.target.value));
									}
									}}
									{...register('precio', { required: 'Precio es requerido' })}
								/>
							</Grid>

							{/* Renderiza los campos solo si el tipo de inmueble es 'Casa' o 'Departamento' */}
	{(tipoInmueble === 'Casa' || tipoInmueble === 'Departamento') && (
		<>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Número de dormitorios"
					fullWidth
					type="number"
					{...register('numDormitorios', { required: tipoInmueble === 'Casa' || tipoInmueble === 'Departamento' ? 'Número de dormitorios es requerido' : false })}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="Número de baños"
					fullWidth
					type="number"
					{...register('numBanios', { required: tipoInmueble === 'Casa' || tipoInmueble === 'Departamento' ? 'Número de baños es requerido' : false })}
				/>
			</Grid>
		</>
	)}

	<Grid item xs={12}>
		<TextField
			label="Descripción"
			fullWidth
			multiline
			rows={8}
			{...register('descripcion', { required: 'Descripción es requerida' })}
		/>
		{errors.descripcion && <p>{errors.descripcion.message}</p>}
	</Grid>

	<Grid item xs={12}>
		<div id='rendermap' style={{ minHeight: 'calc(11em + 11vw)' }}>
			{!latlngmunicipio ? 'Seleccione ubicación para mostrar mapa.' : latlngmunicipio.map((item) => {
				return <SearchGoogleMapsRegistro Vlat={parseFloat(item.Latitud)} Vlng={parseFloat(item.Longitud)} setdireccion={setdire}></SearchGoogleMapsRegistro>

				})
			}
		</div>
	</Grid>
	<Grid item xs={12} display="flex" justifyContent="center" mt={4}>
		<TextField
			label="Celular de contacto"
			fullWidth
			type="number"
			{...register('celular', { required: 'Teléfono es requerido' })}
		/>
	</Grid>
	<Grid item xs={12} display="flex" justifyContent="center" mt={4}>
		<Box>
			{isLoading ? <LoadingSpinner/> : <></>}
		</Box>
	</Grid>
	<Grid item xs={12} display="flex" justifyContent="center">
		<Box mt={4} >
			<Button 					  
				class="botonMapaFiltros"
				variant="contained" 
				type="submit">
				Publicar ahora
			</Button>
		</Box>
	</Grid>
</Grid>
</form>
	</Box>
		{/* Modales que se muestran en la página*/}
		<MensajeModal Mensaje={mensaje} Mostrar={showObtenTuModal} setClose={() => setShowObtenTuModal(false)} ></MensajeModal>
		<Snackbar open={vistobueno} autoHideDuration={4000} onClose={() => setVistoBueno(false)}>
			<Alert
				onClose={() => setVistoBueno(false)}
				severity="success"
				variant="filled"
				sx={{ width: '100%' }}
			>
				Tu anuncio se publicó con éxito
			</Alert>
		</Snackbar>
	</Container>

		);}



export default RegistroSimple;
