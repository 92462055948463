import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
/*Imagen*/
import Layout from "./Components/Layout";
import SearchGoogleMaps from "./Components/SearchGoogleMaps";
import imgHome from "../src/img/img_hoome.jpg";
import imgBtnBuscarHome from "../src/img/buscar.svg";
import ModalRegistroAgente from "./Components/ModalRegistroAgente.js";
import TopMerKasa from "./Components/TopMerKasa";
import TopAgentes from "./Components/TopAgentes";
import TopProyectos from "./Components/TopProyectos";

export default function Home() {
  const url = "https://merkasa.online/api/ApiListarMaestroCbo.php";
  const [maestrosTipoOperacion, setMaestrosTipoOperacion] = useState();
  const [operacion, setOperacion] = useLocalStorage("operacion", 0);
  const [destipousuario, setDestipousuario] = useLocalStorage(
    "destipousuario",
    ""
  );

  const navigate = useNavigate();

  const handleRedirect = () => {
    sessionStorage.setItem("selectOperacion", operacion);
    navigate("/ListAnuncios");
  };

  const handleRedirectProyecto = () => {
    sessionStorage.setItem("selectOperacion", 4);

    navigate("/ListAnuncios");
  };

  const fetchApi = async (tipop) => {
    const data = JSON.stringify({
      tipo: tipop,
    });

    const response = await fetch(url, {
      method: "POST",
      body: data,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const responseJSON = await response.json();
    if (tipop == 2) {
      setMaestrosTipoOperacion(responseJSON.items);
    }
  };

  const errorCallback = (error) => {
    console.log("error", error);
  };
  function generateClave() {
    const clave =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    return clave;
  }
  function registrarVisita() {
    let codigo;
    if (localStorage.getItem("clave") == undefined) {
      codigo = generateClave();
      localStorage.setItem("clave", codigo);
    } else {
      codigo = localStorage.getItem("clave");
    }

    const fecha = new Date().toLocaleString();
    const tipoDeDispositivo = navigator.userAgent + "/Home";

    const data = {
      fecha: fecha,
      latitud: "",
      longitud: "",
      tipoDeDispositivo: tipoDeDispositivo,
      codigo: codigo,
    };

    enviarDatos(data);
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      if (position) {
        const latitud = position.coords.latitude;
        const longitud = position.coords.longitude;

        const data = {
          fecha: "",
          latitud: latitud,
          longitud: longitud,
          tipoDeDipositivo: "",
          codigo: "",
        };

        enviarDatos(data);
      }
    }, errorCallback);

    registrarVisita();
  }, []);
  async function enviarDatos(data) {
    await fetch("https://merkasa.online/api/ApiRegistroVisitas.php", {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => console.log("Datos enviados"))
      .catch((error) => console.error("Error al enviar datos", error));
  }

  useEffect(() => {
    fetchApi(2); //Tipo 1: Es el tipo de inmueble, Tipo 2: Tipo de Operación, Tipo 3: Caracteristicas de inmueble.
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout>
        <>
          <div
            className=" bg-image homeImage"
            style={{
              backgroundImage: `url('${imgHome}')`,
              height: "calc(21.5em + 21.5vw)",
            }}
          >
            <div className="mascara">
              <h1 className="TituloHome">
                <p style={{ color: "white" }}>
                  <b style={{ color: "white" }}>Descubre</b> tu nuevo hogar
                </p>
              </h1>
              <div className="heroOperationContainer">
                <div className="presentacion">
                  <Form id="form-home">
                    <Form.Select
                      aria-label="Default select example"
                      fontWeight="bold"
                      font-style="italic"
                      color="black"
                      className="ComboPrincipalSelect"
                      value={operacion}
                      onChange={(e) => setOperacion(e.target.value)}
                    >
                      {!maestrosTipoOperacion
                        ? "Cargando...."
                        : maestrosTipoOperacion.map((item) => {
                            return (
                              <option key={item.Codigo} value={item.Codigo}>
                                {item.Descripcion}
                              </option>
                            );
                          })}
                    </Form.Select>
                  </Form>
                  <div style={{ height: "36px" }}>
                    <SearchGoogleMaps></SearchGoogleMaps>
                  </div>
                </div>
                <div className="btnBuscarHomeContenedor">
                  <img
                    onClick={(e) => handleRedirect()}
                    src={imgBtnBuscarHome}
                    className="btnBuscarHome"
                    alt="Buscar"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <TopMerKasa></TopMerKasa>
          <center>
            <Link to="/ListAnuncios">
              <button
                key="btnVerDestacados"
                type="button"
                className="btnVerTodas"
              >
                Ver todos
              </button>
            </Link>
          </center>
          <br></br>
          <center>
            <hr style={{ width: "45%" }}></hr>
          </center>
          <br></br>
          <center>
            <p style={{ fontSize: "26pt" }}>
              Conoce a los <b>agentes en Merkasa</b>
            </p>
          </center>
          <br></br>
          <Container style={{ padding: "0 0 0 0" }}>
            <Row>
              <Col sm={12}>
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-end gap-2 gap-md-5">
                  {destipousuario != "Particular" ? (
                    <>
                      <p
                        style={{
                          fontSize: "14pt",
                          whiteSpace: "nowrap",
                          marginBottom: "0",
                        }}
                      >
                        <b>¿Quieres ser uno de ellos?</b>
                      </p>
                      <ModalRegistroAgente></ModalRegistroAgente>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
          <br></br>
          <TopAgentes></TopAgentes>
          <br></br>
          <center>
            <Link to="/ListAgentes">
              <button key="btnVerAgentes" type="button" className="btnVerTodas">
                Ver todos
              </button>
            </Link>
          </center>
          <br></br>
          <center>
            <hr style={{ width: "45%" }}></hr>
          </center>
          <br></br>
          <center>
            <p style={{ fontSize: "26pt" }}>
              Conoce los <b>nuevos proyectos</b>
            </p>
          </center>
          <br></br>
          <TopProyectos></TopProyectos>
          <br></br>
          <center>
            <button
              key="btnVerProyectos"
              type="button"
              className="btnVerTodas"
              onClick={(e) => handleRedirectProyecto()}
            >
              Ver todos
            </button>
          </center>
          <></>
        </>
      </Layout>
    </>
  );
}
