import React, {useState,useEffect  } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import axios from 'axios'; // npm i axios
import CardAgentes from "./CardAgentes";

function ListaAgentes()
{
	let itop = 0;

	const [items2, setItems2] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [isVisible, setIsVisble] = useState('hidden');
	const [idAgente, setIdAgente] = useState(0);

	async function fetchApi(top){
		setIsLoading(true);
		let fd = new FormData() 
		fd.append("top",top)

		const res = await axios.post('https://merkasa.online/api/ApiListarAgentesAll.php',fd);

		if (res.data.items.length > 0){
			setItems2(res.data.items);
			setIsVisble(res.data.items[0].visible);
			setIsLoading(false);}
		}

	useEffect(() => {
		fetchApi(itop);
		window.scroll(0,0);
	},[])


	function filtrarLista(valor)
	{
		setIdAgente(valor);

		const ul = document.getElementById('topmerkasa');    
		const lista = ul.getElementsByTagName("li");
		const arrayAnuncios = Array.from(lista);
		const filteredListItems = arrayAnuncios.filter((li) => li.id == valor);

		arrayAnuncios.forEach((li) => {

			li.style.display = 'flex';
			li.style.flexDirection = 'row'
			li.style.justifyContent = 'center'
		});
		/*arrayAnuncios.filter((a) => parseInt(a.id) == parseInt(valor)).forEach(li => ul.appendChild(li));*/

		if(valor != 0)
		{
			arrayAnuncios.forEach((li) => {
				if (!filteredListItems.includes(li)) {
					li.style.display = 'none';
				}
			});
		}

	}

	return (
		<>
			<Container style={{padding:'0 0 calc(2em + 2vw) 0'}}>
				{/* <Row> */}
				<Col sm={4}>
					<center><h2 style={{paddingTop:'20px'}}>Agentes en Merkasa</h2></center>
				</Col>
				<Col sm={4} className=" w-100">
					<Container>
						<Row>
							<Col sm={12}>
								<Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
									<Form.Label style={{float:'center',fontSize:'10pt',margin:'0 0 0 0'}}>Nombre del Agente</Form.Label>
									<br></br>
									<Form.Select 
										value={idAgente}
										onChange={(e) => filtrarLista(e.target.value)}

										style={{textAlign:'center', height:'40px',fontSize:'8pt'}}
									>
										<option value={0}>TODOS</option>
										{ !items2 ? 'Cargando Top Agentes....' : items2.map( (item,index) => {


										return(
											<option value={item.idAgente}>{item.Nombre +' '+item.ApellidoPaterno + ' '+ item.ApellidoMaterno}</option>
											)
										})}
									</Form.Select>
								</Form.Group>
							</Col>
						</Row>
					</Container>
				</Col>
				<Col sm={4}>
					<Container>
						<Row>
							<Col sm={6}>
							</Col>
							<Col sm={6}>
							</Col>
						</Row>
					</Container>
				</Col>
				{/* </Row> */}
			</Container>
			<Container style={{padding:'0 0 0 0'}}>
				<Row>
					<Col sm={12}>
						{isLoading ? <p>No hay agentes que mostrar</p> :
						<>
						<ul id='topmerkasa'>
							{ !items2 ? 'Cargando Top Agentes....' : items2.map( (item,index) => {


							return(
								<li id={item.idAgente} key={index} className='topagente2'>
									<CardAgentes Id={item.idAgente} VideoUrl={item.videoUrl} Nombre={item.Nombre +' '+item.ApellidoPaterno + ' '+ item.ApellidoMaterno} Visible={item.visible} CantAnuncios={item.CantAnuncios} Descripcion={item.descripcion} Foto={item.fotoperfil} Facebook={item.urlFacebook} Instagram={item.urlInstagram} Tiktok={item.urlTikTok} NumeroCelular={item.Celular}></CardAgentes>
								</li>

								)

							})}

						</ul>
					</>
				}
			</Col>
		</Row>
	</Container>
</>
);

}

export default ListaAgentes;
