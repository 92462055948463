import React, { useState,useEffect } from 'react';
import imgcama from "../img/cama.svg";
import imgbanio from "../img/banio.svg";
import imgcochera from "../img/cochera.svg";
import imgterreno from "../img/medida_02.svg";
import imgconstruido from "../img/medida_01.svg";
import "react-slideshow-image/dist/styles.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Carousel from "react-bootstrap/Carousel";
import LoadingSpinner from "./LoadingSpinner";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function CardAnunciosFavoritos({direccion,Precio,CodigoAnuncio,terreno,construccion,dormitorios,banios,parqueos,Transaccion,Imagenes,IdInmueble,FechaInactivo,EstadoProyecto,Moneda,MonedaExp}) 
{
	const [items, setItems] = useState();
	const [index, setIndex] = useState(0);

	useEffect(() => {
		eliminadoAutomáticoDeFavoritos();	
		fetchApi();
	},[])	

	async function fetchApi()
	{

		const jsonNew = JSON.parse(Imagenes);
		setItems(jsonNew.items);

		//setItems(res.data.items);
		}

	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
		};

	async function eliminadoAutomáticoDeFavoritos(){
		if (FechaInactivo !== undefined){
			const fechaActual = new Date()
			const fechaInactivo = new Date(FechaInactivo)
			const fechaDiferencia = fechaInactivo - fechaActual
			const fechaEnMeses = fechaDiferencia / (1000 * 60 * 60 * 24 * 30)
			if (fechaEnMeses > 3) {
				const data = JSON.stringify({
					idanuncio : CodigoAnuncio,
					})

				await fetch('https://merkasa.online/api/ApiEliminarFavorito.php', {
					method: 'POST',
					body: data,
					headers: {
						'Content-type': 'application/json; charset=UTF-8',
						},
					})
				}

			}
		}
	const handleRedirect = () => {
		window.open('DetalleAnuncio/'+ btoa(CodigoAnuncio));

		}


	const jsonNew = JSON.parse(Imagenes)
	const inmueble = () => {
		let tipo = parseInt(IdInmueble);
		if (tipo===1){
			return "Casa";
			}
		else if (tipo===2){
			return "Departamento";
			}
		else if (tipo===3){
			return "Terreno";
			}
		else if (tipo===4){
			return "Oficina";
			}
		else if (tipo===5){
			return "Casa de campo";
			}
		else if (tipo===6){
			return "Edificio";
			}
		else if (tipo===7){
			return "Local Comercial";
			}
		else if (tipo===8){
			return "Industrial";
			}
		else if (tipo===9){
			return "Parqueo";
			}
		else if (tipo===10){
			return "Agrícola";
			}
		else {
			return ""
			}
		}
	function precioProyecto(){
		if (Precio.indexOf('-') ===-1){
			return Precio;
			}
		else{
			return Precio.substring(0, Precio.indexOf('-'));
			}
		}
	function estadoProyecto(){
		if (EstadoProyecto === '1') {
			return "En Planos";
			}
		if (EstadoProyecto === '2') {
			return "En Construcción";
			}	
		if (EstadoProyecto === '3') {
			return "Finalizado";
			}
		}
function formatoCaracteristicas(){
		let caracteristicas = [];
		if (dormitorios > 0){
			caracteristicas.push(dormitorios+" Dorm ");
			}
		if (banios > 0){
			if (banios == 0){
				caracteristicas.push(banios+" Baños ");
				}
			else{
				caracteristicas.push(banios+" Baño ");
				}
			}
		if (parqueos > 0){
			caracteristicas.push(parqueos+" Parq ");
			}
		if (terreno > 0){
			caracteristicas.push(terreno+" m2 tot.");
			}
		return caracteristicas.join(" | ");
		}


	return (
		<> 
			<Card   style={{width:'298px', height:'380px', border:'1px solid black',cursor:'pointer',overflow:'hidden'}} className="cardVistos">
				<Card.Body>
					<Card.Title style={{width: "100%",
						height: "200px" }}>
						<Carousel activeIndex={index} onSelect={handleSelect} interval={null} indicators={true} >

							{ !items ? <LoadingSpinner></LoadingSpinner> : items.map( (item) => {
								return (
									<Carousel.Item onClick={(e) => handleRedirect()}>
										<LazyLoadImage
											src={item.imagen}
											style={{
												objectFit: "cover",width:'100%',margin:'0 0 0 0',height:'200px' }}
											></LazyLoadImage>
										</Carousel.Item>
									)
									})      
								}
							</Carousel>
						</Card.Title>
						<Card.Text style={{minHeight:'10rem',widht:'100%'}} onClick={handleRedirect}>
							<br></br>
							<Container >
								{Transaccion =="Proyecto" ? 
								<Row style={{height:'43px'}}>
									<Col xs={6} sm={7}>
										<div className='divPrecioTopNew'>
											<p style={{display:'flex'}}>Desde {Moneda==="BS"?"Bs":'$us'} {parseInt(Precio.toString().replace(/,/g,''))}</p>
										</div>
									</Col>
									<Col xs={6} sm={5}>
										<b><p  className='divTransaccionTopNew'style={{fontSize:"7.5pt",padding:'0 0 0 0',textAlign:"center",lineHeight:"15px",overflowWrap:"break-word",whiteSpace:'normal'}}>{Transaccion} {estadoProyecto()}</p></b>
									</Col>

								</Row>
										:<Row className='datosAnuncios'>
											<b><p style={{fontSize:"14pt",padding:'0 0 0 0',position:'relative',top:'5px',height:'30px',whiteSpace:'nowrap',margin:'0 0 10px 0'}}>{inmueble()} en {Transaccion}</p></b>
											<div className='divPrecioTopNew' style={{position:'relative',bottom:'2px', margin:'0 0 10px 10px'}}>
												<p>{Moneda==="BS"?"Bs":'$us'} {Precio}</p>
											</div>

											<p style={{fontSize:'11pt',margin:'0 0 10px 10px',whiteSpace:'nowrap'}} >{formatoCaracteristicas()}</p> 
											<p style={{fontSize:'10pt',margin:'0 0 10px 10px'}}>{direccion}</p>

									</Row>}
										{Transaccion =='Proyecto' ?
										<><Row style={{flexWrap:'nowrap',height:'20px',gap:'0px'}} id='card-detalle-agente'>
											{dormitorios == 0 ? <></>:<><Col id='dormitorio-project' style={{whiteSpace:'nowrap',padding:'0 0 0 15px', width:'33%'}}>
										<p style={{fontSize:"10pt"}}><img src={imgcama} style={{height:'12px'}} ></img><b> Desde {dormitorios} dormitorios</b></p>
						</Col></>}

						{banios == 0 ? <></>:<><Col style={{whiteSpace:'nowrap',padding:'0 0 0 10px', width:'33%'}} id='banio-project'>
					<p style={{fontSize:"10pt"}} ><img src={imgbanio} style={{height:'12px'}}></img><b> Desde {banios} baños</b></p>
	</Col></>}

	{construccion == 0 ? <></>:<><Col style={{whiteSpace:'nowrap',padding:'0 0 0 0', width:'33%'}} id='const-project'>
<p style={{fontSize:"10pt"}} ><img src={imgconstruido} style={{height:'12px'}} ></img><b> {construccion} m²</b></p>
		  </Col></>}


	  </Row>
	  <Row>
		  <Col xs={12}>
			  <div style={{borderBottom:"1px solid #9c9c9c"}}>
			  </div>
		  </Col>
	  </Row>
	  <Row>
		  <Col xs={12}>
			  <p style={{textAlign:"left",fontSize:"10pt",paddingTop:"10px"}}>{direccion}</p>
		  </Col>
	  </Row>
  </>
												:null}
											</Container>
										</Card.Text>
									</Card.Body>
								</Card>
							</>

							);
}

export default CardAnunciosFavoritos;
