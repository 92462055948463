import React, { useState,useEffect,useRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CardAnunciosUsuario from './CardAnunciosUsuario';
import axios from 'axios'; // npm i axios
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { useLocalStorage } from '../useLocalStorage';
import AgentePromocionate2 from '../AgentePromocionate2';
import CardAnunciosInteresadosUsuario from './CardAnunciosInteresadosUsuario';
import {FaCamera} from 'react-icons/fa'; // npm i react-icons
import CanvasPerfil from './CanvasPerfil';
import Recortar from './ModalCrop';
import { Modal} from "react-bootstrap";
import CardAnunciosUsuarioVistos from './CardAnunciosUsuarioVistos';
import CardAnunciosFavoritos from './CardAnunciosFavoritos';
import Pagination from "./Pagination"
import {Snackbar, Alert} from '@mui/material';
function TabUsuario(){
	const [tabIndex, setTabIndex] = useLocalStorage('tabIndex',0);
	const [enlace, setEnlace] = useState('');
	const [tabIndex2, setTabIndex2] = useLocalStorage('tabIndex2',0);
	const [items, setItems] = useState(); 
	const [items2, setItems2] = useState();
	const [itemsAgente, setitemsAgente] = useState();
	const [itemsAnuncioVistos, setitemsAnuncioVistos] = useState();
	const [nombre, setNombre] = useState('');
	const [celular, setCelular] = useState('');
	const [correo, setCorreo] = useState('');
	const [apellidoPaterno, setApellidoPaterno] = useState('');
	const [tipoDocumento, setTipoDocumento] = useState();
	const [numeroDocumento, setNumeroDocumento] = useState();
	const [idusuario, setIdusuario] = useLocalStorage('idusuario','');
	const [imagen, setImagen] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [imageCrop, setImageCrop] = useState();
	const [imagencortada,setImagenCortada] = useState()
	const [favoritos,setItemsFavoritos] = useState();
	const [interesados, setInteresados] = useState();
	const [vOrden, setVOrden] = useState('1');
	const [currentPage, setCurrentPage] = useState(1);
	const [sumaAnuncios, setSumaAnuncios] = useState(0)
	const [vistoBueno,setVistoBueno] = useState(false);
	var urlimagen = useRef("");
	var tipoUsuario = localStorage.getItem("destipousuario").replace(/"/g,"");
	async function fetchApi(){
		let fd = new FormData() 
		fd.append("idusuario",parseInt(idusuario))

		const res = await axios.post('https://merkasa.online/api/ApiListarAnunciosUsuario.php', fd);
		setItems(res.data.items);
		let fd2 = new FormData() 
		fd2.append("top",0)
		fd2.append("idusuario",parseInt(idusuario))

		const res2 = await axios.post('https://merkasa.online/api/ApiListarAnunciosInteresadosUsuario.php', fd2);

		let anunciosInteresados = res2.data.items !== undefined ? res2.data.items : [];
		let i = 0;let datosInteresado = []; 

		//Obtenemos los datos de los interesados del idAnuncio de la consulta anterior
		if (anunciosInteresados.length > 0){
			anunciosInteresados.sort((a, b) => b.idAnuncio - a.idAnuncio);
			let id = anunciosInteresados[0].idAnuncio;

			while (i < anunciosInteresados.length){
				if (id === anunciosInteresados[i].idAnuncio)
					{
					datosInteresado.push({idAnuncio:id,Nombre: anunciosInteresados[i].Nombre,ApellidoPaterno: anunciosInteresados[i].ApellidoPaterno,NumeroDocumento: anunciosInteresados[i].NumeroDocumento,correo: anunciosInteresados[i].correo,celular: anunciosInteresados[i].celular,FechaVisita: anunciosInteresados[i].FechaVisita});
					i+=1;
					}
				else{
					id = anunciosInteresados[i].idAnuncio;
					}
				}
			setInteresados([...datosInteresado]);
			//Eliminar Duplicados
			anunciosInteresados = anunciosInteresados.filter((value,index,self) => self.findIndex((t) => t.idAnuncio === value.idAnuncio) === index); 
			setItems2(anunciosInteresados);
			}

		let fd4 = new FormData() 
		fd4.append("pidusuario",parseInt(idusuario))

		const res3 = await axios.post('https://merkasa.online/api/ApiObtenerUsuariof.php', fd4);
		res3.data.items.map((item) => {

			setNombre(item.Nombre)
			setApellidoPaterno(item.ApellidoPaterno)
			setCorreo(() => item.correo == null ? '' :item.correo)
			setCelular(item.celular)
			setTipoDocumento(item.tipoDocumento)
			setNumeroDocumento(item.NumeroDocumento)
			setImagen([{src:item.foto,height:178, width:178,x:0,y:0,index:0}])
			urlimagen.current = item.foto; 
			enlaceCambioContraseña(item.correo);
			})
		let fdA = new FormData() 
		fdA.append("idusuario",idusuario)

		const responseAgente = await axios.post('https://merkasa.online/api/ApiObtenerAgente.php',fdA);

		setitemsAgente(responseAgente.data.items);


		let f5 = new FormData() 
		f5.append("idusuario",idusuario)
		setitemsAnuncioVistos([]);
		const res5 = await axios.post('https://merkasa.online/api/ApiListarAnunciosUsuarioVistas.php', f5);
		if (res5.data.items !== undefined){
			setitemsAnuncioVistos(res5.data.items);
			}


		let f6 = new FormData() 
		f6.append("idusuario",idusuario)
		const res6 = await axios.post('https://merkasa.online/api/ApiListarAnunciosUsuarioFavoritos.php', f6);
		if (res6.data.items !== undefined){
			setItemsFavoritos(res6.data.items);
			setSumaAnuncios(res6.data.items.length);
			}
		}

	const handleSubmit = (e) => {

		e.preventDefault();
		if (celular.length !== 8){
			alert("El número de celular debe tener 8 dígitos.");
			return
			}
		updateusuario(nombre,apellidoPaterno,correo,celular,numeroDocumento,idusuario);
		}; 

	const handleEnviarCorreo = () =>{


		if(anuncioscorreo.length == 0)
			{
			alert("Debe seleccionar al menos un interesado para continuar.")
			return;
			}

		if(window.confirm('Se enviará una encuesta de satisfacción'))
			{
			const UsuActivo = sessionStorage.getItem("UsuarioActivo");
			const UsuActivoS  = JSON.parse(UsuActivo);

			anuncioscorreo.map(async(item) =>{

				let fd = new FormData() 
				fd.append("correo",item.correo)
				fd.append("nombre",item.nombre)
				fd.append("correoagente",UsuActivoS)
				fd.append("idanuncio",item.idanuncio)

				const res = await axios.post('https://merkasa.online/api/ApiSendMailEncuesta.php', fd);

				})

			const checkboxes = document.querySelectorAll('input[type="checkbox"]');
			checkboxes.forEach((cb) => {
				cb.checked = false;
				});

			setanunciocorreo([]);

			alert("Se envio satisfactoriamente la encuesta de satisfacción.")
			}

		}
	let cimage = useRef(0);
	async function updateusuario(pnombre,papellidopaterno,pcorreo,pcelular,pnumerodocumento,pidusuario) {
		const tipousuario = localStorage.getItem("idtipousuario");

		const imgFinal = document.getElementById('canvas-perfil1');
		console.log("la foto de perfil",imgFinal);
		let url1;
		if (imgFinal !== null){
			url1 = imgFinal.toDataURL("image/webp");
			}
		else{
			url1 = urlimagen.current;
			}
		const data = JSON.stringify({
			pnombre : pnombre,papellidopaterno: papellidopaterno,pcorreo : pcorreo,pcelular : pcelular,pnumerodocumento : pnumerodocumento,pidusuario:pidusuario,imagenbs4:url1,cambioImagen:cimage.current, tipousuario:tipousuario})
		await fetch('https://merkasa.online/api/ApiActualizaUsuario.php', {
			method: 'POST',
			body: data,
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
				},
			})
		.then((response) => response.json())
		.then((data) => {
			//setPosts((posts) => [data, ...posts]);

			const respuesta = JSON.parse(data);

			if(respuesta === 1)
				{
				setVistoBueno(true);
				return; 
				}else{

					alert("Hubo un error al actualizar los datos, intente mas tarde.")
					}
			})
		.catch((err) => {
			console.log(err.message);
			});
		};



	const navigate = useNavigate();

	const handleRedirect = () => {

		/*     
	sessionStorage.setItem("CodAnuncioActualSeleccion",JSON.stringify(CodigoAnuncio));
	sessionStorage.setItem("LatitudActualSeleccion",JSON.stringify(Latitud));
	sessionStorage.setItem("LongitudActualSeleccion",JSON.stringify(Longitud));
	sessionStorage.setItem("DescripcionActualSeleccion",JSON.stringify(Descripcion));
	sessionStorage.setItem("PrecioActualSeleccion",JSON.stringify(Precio));
	sessionStorage.setItem("TerrenoActualSeleccion",JSON.stringify(Terreno));
	sessionStorage.setItem("ConstruccionActualSeleccion",JSON.stringify(Construccion));
	sessionStorage.setItem("DormitoriosActualSeleccion",JSON.stringify(Dormitorios));
	sessionStorage.setItem("BaniosActualSeleccion",JSON.stringify(Banios));
	sessionStorage.setItem("ParqueosActualSeleccion",JSON.stringify(Parqueos));
	sessionStorage.setItem("DireccionActualSeleccion",JSON.stringify(direccion));
	sessionStorage.setItem("TransaccionActualSeleccion",JSON.stringify(Transaccion));
	sessionStorage.setItem("TituloActualSeleccion",JSON.stringify(Titulo));
	sessionStorage.setItem("NombreActualSeleccion",JSON.stringify(Nombre));
	sessionStorage.setItem("ApellidoPaternoActualSeleccion",JSON.stringify(ApellidoPaterno));
	sessionStorage.setItem("CelularActualSeleccion",JSON.stringify(Celular));
	const jsonNew = JSON.parse(ListaImagenes);
	sessionStorage.setItem("ImagenActualSeleccion",JSON.stringify(jsonNew.items[0].imagen));

	navigate('/DetalleAnuncio');*/


		}

	const [anuncioscorreo, setanunciocorreo] = useState([]);

	const addItem = (e,id,correo,nombre) => {

		/*setImagen(imagen => [...imagen,{ id:index,src:src, height:height, width: width}])*/

		setanunciocorreo(anuncioscorreo.filter(item => parseInt(item.index) != parseInt(id)));

		if(e.target.checked)
			{
			setanunciocorreo(anuncioscorreo => [ ...anuncioscorreo, {
				index:id,
				idanuncio: e.target.value,
				activo:(e.target.checked ? 1 : 0),
				correo: correo,
				nombre: nombre
				}])
			}else{
				setanunciocorreo(anuncioscorreo.filter(item => parseInt(item.index) != parseInt(id)));
				}

		}

	const handleCLickTab = (index) =>
		{
			setTabIndex(index)
		}
	function enlaceCambioContraseña(correoA){
		const today = new Date().toLocaleDateString('es-ES').replace(/\//g, '-');
		const fecha = new Date().toISOString().slice(0, 10).replace(/\//g, '-');
		let fechaCodificada = btoa(fecha);
		setEnlace(`https://merkasa.online/api/ApiCambioContrasenia.php?fecha=${encodeURIComponent(fechaCodificada)}&correo=${correoA}&mensaje=''`);
		}

	useEffect(() => {
		fetchApi();
		window.scrollTo(0, 10);
		},[])
	const fileInputRef = useRef(null);

	const handleOpenFileClick = () => {
		fileInputRef.current.click();
		};

	function niceBytes(a)
	{
		var marker = 1024; // Change to 1000 if required
		var decimal = 3; // Change as required
		var kiloBytes = marker; // One Kilobyte is 1024 bytes
		var megaBytes = marker * marker; // One MB is 1
		return(a / megaBytes).toFixed(decimal);
		}
	var cropref = useRef(null);

	const handleImageCrop = (croppedArea, croppedAreaPixels) => {
		setImagenCortada(croppedAreaPixels)
		};
	const onClose = () => {
		setShowModal(false)
		};

	function recortarImagen(e){
		setShowModal(true)	
		cimage.current = 1;
		const file = e.target.files[0];
		if(niceBytes(file.size) >= 32.0)
			{
			alert("Imagen excede el tamaño permitido. El peso debe ser menor a 32Mb.")
			return;
			}
		const src = URL.createObjectURL(file);
		setImageCrop(src);
		}
	function convertImage()
	{
		const src = imageCrop;
		const height = imagencortada.height;
		const width = imagencortada.width;
		const x = imagencortada.x;
		const y = imagencortada.y;

		if(src.length > 0)
			{
			setImagen([{ src:src, height:height, width: width, x:x, y:y, index:1}]);
			}
		setShowModal(false)
		};
	const handleOrden = (tipo) => {

		setVOrden(tipo);

		}
	function ordenarPorFecha(fecha){
		let fechaVista = fecha.replace(/ /g, "T");
		var date1 = new Date(fechaVista);
		var date2 = new Date();
		var diffTime = Math.abs(date2 - date1);
		var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
		switch(vOrden) {
				case '1':return diffDays <= 7;
				case '2':return diffDays <= 30;
				case '3':return diffDays <= 90;
				default: return false;
				}

		}
	const handlePageChange = (page) => {
		setCurrentPage(page);
		window.scrollTo(0, 0);
		};
	return (
		<Tabs id='tabprincipal' selectedIndex={tabIndex} onSelect={(index) => handleCLickTab(index)} style={{borderBottom: '1px', borderColor: 'black',fontSize:'10pt'}}>
			<TabList>
				<Tab value={1}>Mis Avisos</Tab>
				<Tab value={2}>Interesados</Tab>
				<Tab value={3}>Vistos Recientemente</Tab>
				<Tab value={4}>Mis Favoritos </Tab>
				<Tab value={5}>Mi cuenta</Tab>
			</TabList>
			<TabPanel value={1}>
				<div id="MisAvisosUsuario" style={{height:'100%',padding:'0 0 0 0',overflowY:'auto',marginbottom:'0 0 30px 0'}}>


					{ !items || items==0 ? <p>Sin avisos que mostrar</p> : items.map( (item) => {
						return (
							<CardAnunciosUsuario Id={item.idAnuncio} direccion={item.Direccion} Precio={item.Precio}  AnioContruccion={item.anioconstruccion}ListaImagenes={item.Imagenes} VisibleDestacar={item.Destacado} CodigoAnuncio={item.idAnuncio} Latitud={item.Latitud} Longitud={item.Longitud} Descripcion={item.Descripcion} Terreno={item.terreno} Construccion={item.construccion} Dormitorios={item.dormitorios} Banios={item.banios} Parqueos={item.parqueos} Transaccion={item.Transaccion} Titulo={item.Titulo} Nombre={item.Nombre} ApellidoPaterno={item.ApellidoPaterno} Celular={item.celular} Visualizaciones={item.Visualizaciones} Escaneos = {item.Escaneos} Interesados={item.Interesados} Imagenes={item.Imagenes} CodDepartamento={item.CodigoDepartamento} CodProvincia={item.CodigoProvincia} CodMunicipio={item.CodigoMunicipio} TipoInmueble={item.idTipo_inmueble} IdTransaccion={item.idTransaccion} Barrio={item.Barrio} EstadoProyecto={item.EstadoProyecto} FechaEntrega={item.FechaEntregaProyecto}MonedaPrecio= {item.MonPrecio} ></CardAnunciosUsuario> )

							})      
						}
					</div>  
				</TabPanel>
				<TabPanel value={2}>
					<Container>
						<Row>
							{!items2 ? <p>Sin interesados que mostrar</p> : items2.map( (item,index) => {
								return (
									<>
										<Col sm={4} style={{position:'relative',marginBottom:'50px'}} className='card-interesados-contenedor'>
											<CardAnunciosInteresadosUsuario Id={item.idAnuncio} direccion={item.Direccion} Precio={item.Precio} ListaImagenes={item.Imagenes} VisibleDestacar={item.Destacado} CodigoAnuncio={item.idAnuncio} Latitud={item.Latitud} Longitud={item.Longitud} Descripcion={item.Descripcion} terreno={item.terreno} construccion={item.construccion} dormitorios={item.dormitorios} banios={item.banios} parqueos={item.parqueos} Transaccion={item.Transaccion} Titulo={item.Titulo} Nombre={item.Nombre} ApellidoPaterno={item.ApellidoPaterno} Celular={item.celular} Visualizaciones={item.Visualizaciones} Interesados={item.Interesados} Imagenes={item.Imagenes} TipoInmueble={item.TipoInmueble} EstadoProyecto={item.EstadoProyecto} interesados={interesados} Adicionar={addItem} Moneda = {item.moneda} MonedaExp = {item.monedaExp}></CardAnunciosInteresadosUsuario>

										</Col>

										<Col sm={8} style={{overflowY:'auto',height:'350px'}} id='lista-interesados'>
											<ul className='interesados_ul'>
												{interesados.filter((interesado) => interesado.idAnuncio === item.idAnuncio).map((datos) => {
													return (
														<li >
															<Container  key={datos.id}  fluid>
																<Row>
																	<Col sm={3}>
																		<p style={{fontSize:'7pt',textAlign:'center'}}><b>{datos.Nombre+' '+datos.ApellidoPaterno}</b></p>
																	</Col>
																	<Col sm={3}>
																		<p style={{fontSize:'7pt',textAlign:'center'}}><b>{datos.correo}</b></p>
																	</Col>
																	<Col sm={2}>
																		<p style={{fontSize:'7pt',textAlign:'center'}}><b>{datos.celular}</b></p>
																	</Col>
																	<Col sm={2}>
																		<p style={{fontSize:'7pt',textAlign:'center'}}><b>{datos.FechaVisita}</b></p>
																	</Col>
																</Row>
															</Container>
														</li>)
													})}
												</ul>

											</Col>
										</>
									)})}
								</Row>
							</Container>
						</TabPanel>
						<TabPanel value={3}>
							<Container>
								<Row style={{margin:'0 0 0 0'}}>
									<Col sm={1} style={{position:'relative',top:'10px'}}>
										<p><b>Ordenar:</b></p>
									</Col>
									<Col sm={11} style={{width:'190px'}}>
										<Form.Select 
											value={vOrden}
											onChange={(e) => handleOrden(e.target.value)}
										>
											<option value={1}>Última semana</option>
											<option value={2}>Último mes</option>
											<option value={3}>Últimos 3 meses</option>
										</Form.Select>
									</Col>
								</Row>
							</Container>
							<br/>

							<div className='vistos-favoritos'>
								{ !itemsAnuncioVistos ? <p>No has visto ningún anuncio</p> : itemsAnuncioVistos.map( (item) => {
									if (ordenarPorFecha(item.FechaVisita)===true) {
										return (
											<CardAnunciosUsuarioVistos  direccion={item.Direccion} Precio={item.Precio}  CodigoAnuncio={item.idAnuncio}  Descripcion={item.Descripcion} terreno={item.terreno} construccion={item.construccion} dormitorios={item.dormitorios} banios={item.banios} parqueos={item.parqueos} Transaccion={item.Transaccion}  Imagenes={item.Imagenes} IdInmueble={item.idTipo_inmueble} EstadoProyecto={item.EstadoProyecto} Moneda={item.MonPrecio}></CardAnunciosUsuarioVistos>)}
												})      
											}
										</div>

									</TabPanel>
									<TabPanel value={4}>


										<div className='vistos-favoritos'>
											{ !favoritos ? <p>No marcaste ningún anuncio como favorito</p> : favoritos.slice((currentPage-1) * 12, currentPage * 12).map((item) => {
												return (
													<CardAnunciosFavoritos  direccion={item.Direccion} Precio={item.Precio}   CodigoAnuncio={item.idAnuncio}  Descripcion={item.Descripcion} terreno={item.terreno} construccion={item.construccion} dormitorios={item.dormitorios} banios={item.banios} parqueos={item.parqueos} Transaccion={item.Transaccion}  Imagenes={item.Imagenes} IdInmueble={item.idTipo_inmueble} FechaInactivo = {item.FechaInactivo} EstadoProyecto={item.EstadoProyecto} Moneda={item.MonPrecio} MonedaExp={item.MonExpensas}></CardAnunciosFavoritos>)
													})      

												}

											</div>
											<div>
												<Pagination
													pages={Math.ceil(sumaAnuncios/12)}
													current={currentPage}
													onPageChange={handlePageChange}
												/>
											</div>
										</TabPanel>
										<TabPanel value={5}>
											<Container>
												<Row>
													<Col sm={2}>
													</Col>
													<Col sm={8}>
														<br></br>
														<Tabs selectedIndex={tabIndex2} onSelect={(index) => setTabIndex2(index)} style={{borderBottom: '1px', borderColor: 'black',fontSize:'10pt'}}>
															<TabList style={{fontSize:'8pt'}}>
																<Tab value={5}>Datos personales</Tab>
																{tipoUsuario==='Profesional' ? <Tab value={7}>Promocionate como agente</Tab>:null}

															</TabList>
															<TabPanel value={6}>
																<Form onSubmit={handleSubmit}>
																	<Container style={{padding:'calc(1em + 1vw) 0 0 0'}}>

																		<Row>
																			<Col xs={12}>
																				<p style={{fontSize:'10pt'}}><b>Completa tus datos personales</b></p>
																			</Col>
																		</Row>
																		<Row style={{paddingBottom:'15px'}}>
																			<Col xs={4}>
																				<div className='bgperfil'>
																					{!imagen ? 'Cargando imagenes' : imagen.map( (item,index) => {
																						/*<Canvas src={item.src} width={item.width} height={item.height} ></Canvas>*/
																						return(<>
																						<CanvasPerfil src={item.src} width={item.width} height={item.height} x = {item.x} y = {item.y} index={item.index}></CanvasPerfil>
																					</>
																					)
																					}) 
																				}
																				<div style={{borderTop:'1px solid #9c9c9c',bottom:'0',height:'20px',width:'100%',position:'absolute',borderBottomRightRadius:'10px',borderBottomLeftRadius:'10px',background: 'linear-gradient(to right,rgba(255,255,255,0), #9c9c9c)',zIndex:'1',cursor:'pointer'}} onClick={handleOpenFileClick}>
																					<center><FaCamera style={{cursor:'pointer'}}></FaCamera></center>
																					{/*  <input
											type="file"
											ref={fileInputRef}
											style={{ display: 'none' }}
											onChange={handleFileSelected}
										/>*/}
										<input id="fileAnuncio" type="file" ref={fileInputRef}
											style={{ display: 'none' }} accept="image/png,image/jpeg,image/jpe"
											onChange={(e) => recortarImagen(e)}/>
								</div> </div>
							</Col>



							<Modal  show={showModal} onHide={onClose} size="md">
								<Modal.Header closeButton>
									<Modal.Title style={{width:'100%',textAlign:'center', fontSize:'12pt',fontWeight:'bold'}}>Seleccione el área de la foto que quiere mostrar</Modal.Title>
								</Modal.Header>
								<Modal.Body style={{display:'flex', justifyContent:'center'}}>
									{imageCrop && (
										<Recortar imageSrc={imageCrop} onCropComplete={handleImageCrop} />
									)}

								</Modal.Body>
								<Modal.Footer style={{display:'flex',justifyContent:'center'}}>
									<button className="botonMapaFiltros" onClick={convertImage} >Aceptar</button>
									<button className="botonMapaFiltros" onClick={onClose} >Cerrar</button>
								</Modal.Footer>
							</Modal>

							<Col xs={4}>
							</Col>
							<Col xs={4}>
							</Col>
						</Row>
						<Row>
							<Col xs={6}>
								<Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
									<Form.Label style={{fontSize:'10pt'}}>Nombre</Form.Label>
									<Form.Control
										type="text"
										value={nombre}
										onChange={(e) => setNombre(e.target.value)}
										required
										style={{fontSize:'8pt'}}
									/>
								</Form.Group>
							</Col>
							<Col xs={6}>
								<Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
									<Form.Label style={{fontSize:'10pt'}}>Apellido</Form.Label>
									<Form.Control
										type="text"
										value={apellidoPaterno}
										onChange={(e) => setApellidoPaterno(e.target.value)}
										required
										style={{fontSize:'8pt'}}
									/>
								</Form.Group>
							</Col>
						</Row>
						<br></br>
						<Row>
							<Col xs={12}>
								<p style={{fontSize:'10pt'}}><b>Contacto</b></p>
							</Col>
							<Col xs={12}>
								<p style={{fontSize:'10pt'}}>Estos datos te servirán para contactarnos contigo</p>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
									<Form.Label style={{fontSize:'10pt'}}>Celular</Form.Label>
									<Form.Control
										type="text"
										value={celular}
										onChange={(e) => setCelular(e.target.value)}
										required
										style={{fontSize:'8pt'}}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
									<Form.Label style={{fontSize:'10pt'}}>Email</Form.Label>
									<Form.Control
										type="email"
										value={correo}
										onChange={(e) => setCorreo(e.target.value)}
										required
										readOnly={true}
										disabled
										style={{fontSize:'8pt'}}
									/>
								</Form.Group>
							</Col>

						</Row>

						<Row>
							<Col xs={12}>
								<a href={enlace} target="_blank" rel="noreferrer">Cambiar contraseña</a>
							</Col>
						</Row>

						<Row>
							<Col xs={12}>
								<br></br>
								<center>
									<Button variant="primary4" type='submit'>
										<b>Guardar Cambios</b>
									</Button>
								</center>
							</Col>
						</Row>
					</Container>
				</Form>

			</TabPanel>
			<TabPanel value={7}>
				<AgentePromocionate2></AgentePromocionate2>
			</TabPanel>
		</Tabs>
	</Col>
	<Col sm={2}>
	</Col>
</Row>
{/* Modales que se muestran en la página*/}
<Snackbar open={vistoBueno} autoHideDuration={4000} onClose={() => setVistoBueno(false)}>
	<Alert
		onClose={() => setVistoBueno(false)}
		severity="success"
		variant="filled"
		sx={{ width: '100%' }}
	>
		Se guardaron los cambios exitosamente
	</Alert>
</Snackbar>
	</Container>

</TabPanel>
  </Tabs>
  );

}


export default TabUsuario
