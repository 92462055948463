import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import imgVendedor from "../img/face_no perfil.svg"
import wsp2 from "../img/whatsaap_2.svg"
import facebook from "../img/face.svg";
import instagram from "../img/inst.svg";
import tiktok from "../img/tiktok.svg";
import CardAgentesDetalle from '../Components/CardAgentesDetalle';
import Form from 'react-bootstrap/Form';
import ReactPlayer from 'react-player'
import axios from 'axios'; // npm i axios
import LoadingSpinner from "./LoadingSpinner";
import { useLocalStorage } from '../useLocalStorage';
import Modal from 'react-bootstrap/Modal';
import Canvas from './Canvas';
import CardAnunciosAgente from './CardAnunciosAgente';

function Perfilagente() {
	const [sidAgenteSeleccionado, setidAgenteSeleccionado] = useState('');
	const [sNombreAgenteSeleccionado, setNombreAgenteSeleccionado] = useState();
	const [sFotoPerfilAgenteSeleccionado, setFotoPerfilAgenteSeleccionado] = useState(null);
	const [sCantidadAnuncios, setCantidadAnuncios] = useState();
	const [videosagentes, setVideosAgentes] = useState();
	const [anunciosagentes, setAnunciosAgentes] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [bvisibleDatosContacto, setVisibleDatosContacto] = useState(true);
	const [tipobotton, setTipoBotton] = useState('submit');
	const [megusta, setMegusta] = useState(false);
	const [celular, setCelular] = useState();
	const [descripcion, setDescripcion] = useState();
	const [idusuario, setIdusuario] = useLocalStorage('idusuario', '');
	const [show, setShow] = useState(false);
	const [correoContacto, setCorreoContacto] = useState('');
	const [nombreContacto, setNombreContacto] = useState('');
	const [apellidoContacto, setApellidoContacto] = useState('');
	const [celularContacto, setCelularContacto] = useState('');
	const [vinstagram, setinstagrams] = useState(null);
	const [vfacebook, setfacebook] = useState(null);
	const [vtiktok, settiktok] = useState(null);

	const [fotos, setFotos] = useState([]);

	const runEffect = async () => {

		const idAgenteSeleccionado = sessionStorage.getItem("idAgenteSeleccionado");
		const NombreAgenteSeleccionado = sessionStorage.getItem("NombreAgenteSeleccionado");
		const CantidadAnunciosSeleccionado = sessionStorage.getItem("CantidadAnuncios");
		const DescripcionAgenteSeleccionado = sessionStorage.getItem("DescripcionAgenteSeleccionado");
		const FotoPerfilAgenteSeleccionado = sessionStorage.getItem("FotoPerfilAgenteSeleccionado");
		const FacebookAgenteSeleccionado = sessionStorage.getItem("FacebookAgenteSeleccionado");
		const InstagramAgenteSeleccionado = sessionStorage.getItem("InstagramAgenteSeleccionado");
		const TiktokAgenteSeleccionado = sessionStorage.getItem("TiktokAgenteSeleccionado");
		const NumeroCelularAgenteSeleccionado = sessionStorage.getItem("NumeroCelularAgenteSeleccionado");

		//CantidadAnuncios

		setNombreAgenteSeleccionado(NombreAgenteSeleccionado);
		setCantidadAnuncios(CantidadAnunciosSeleccionado);
		setDescripcion(DescripcionAgenteSeleccionado);
		setidAgenteSeleccionado(idAgenteSeleccionado);
		setFotoPerfilAgenteSeleccionado(FotoPerfilAgenteSeleccionado);
		setCelular(NumeroCelularAgenteSeleccionado);
		settiktok(TiktokAgenteSeleccionado);
		setinstagrams(InstagramAgenteSeleccionado);
		setfacebook(FacebookAgenteSeleccionado);

	}
async function ObtenerDatosAgente()
	{

		var idusuario = localStorage.getItem("idusuario");

		let fdA = new FormData() 
		fdA.append("idusuario",idusuario)

		const responseAgente = await axios.post('https://merkasa.online/api/ApiObtenerAgente.php',fdA);
		if (responseAgente.data.items.length > 0){
			responseAgente.data.items.map((item) => 
			{

				sessionStorage.setItem("UsuarioActivo",JSON.stringify(item.correo));

				/*Datos para redireccionar al perfil del agente*/

				sessionStorage.setItem("idAgenteSeleccionado",item.idAgente);
				sessionStorage.setItem("NombreAgenteSeleccionado",item.Nombre +' '+item.ApellidoPaterno + ' '+ item.ApellidoMaterno);
				sessionStorage.setItem("CantidadAnuncios",item.CantAnuncios);
				sessionStorage.setItem("DescripcionAgenteSeleccionado",item.descripcion);
				sessionStorage.setItem("FotoPerfilAgenteSeleccionado",item.fotoperfil);
				sessionStorage.setItem("FacebookAgenteSeleccionado",item.urlFacebook);
				sessionStorage.setItem("InstagramAgenteSeleccionado",item.urlInstagram);
				sessionStorage.setItem("TiktokAgenteSeleccionado",item.urlTikTok);
				sessionStorage.setItem("NumeroCelularAgenteSeleccionado",item.Celular);
				})}
		}

	async function fetchApi() {

		await ObtenerDatosAgente();
		window.scrollTo(0, 0);

		const UsuActivo = sessionStorage.getItem("UsuarioActivo");

		if (UsuActivo != null) {
			setVisibleDatosContacto(false);
			setTipoBotton('button');
		}

		//Listar videos
		let pIdAgente = sessionStorage.getItem("idAgenteSeleccionado");

		let fd = new FormData()
		fd.append("idagente", pIdAgente)
		const response = await axios.post('https://merkasa.online/api/ApiListarVideosAgentes.php', fd);
		setVideosAgentes(response.data.items);

		//Listar los inmuebles en venta
		let fdp = new FormData()
		fdp.append("idagente", pIdAgente)
		const res2 = await axios.post('https://merkasa.online/api/ApiListarAnunciosAgente.php', fdp);
		setAnunciosAgentes(res2.data.items);

		//Listar las fotos de los agentes
		let fdi = new FormData()
		fdi.append("idagente", pIdAgente)
		const response3 = await axios.post('https://merkasa.online/api/ApiObtenerAgenteImagenes.php', fdi);
		setFotos(response3.data.items);
	}

	const registrointeresado = async () => {

		if (idusuario === '') {
			//usuario generico cuando no inicia sesión, el id=1 es un usuario que no esta registrado en la base de datos
			let fd = new FormData()
			fd.append("idagente", sidAgenteSeleccionado)
			fd.append("idusuario", 1)
			fd.append("correo", correoContacto)
			fd.append("nombre", nombreContacto)
			fd.append("apellido", apellidoContacto)
			fd.append("celular", celularContacto)

			await axios.post('https://merkasa.online/api/ApiSendMailAgente.php', fd);
		}
		else {
			let fd = new FormData()
			fd.append("idagente", sidAgenteSeleccionado)
			fd.append("idusuario", idusuario)
			fd.append("correo", correoContacto)
			fd.append("nombre", nombreContacto)
			fd.append("apellido", apellidoContacto)
			fd.append("celular", celularContacto)

			await axios.post('https://merkasa.online/api/ApiSendMailAgente.php', fd);
		}

	}

	const handleClose = () => {
		setShow(false)
	};



	// Funciones para dar formato --------------------------------------
	function soloNumeros(number) {

		let newValor = number.replace(/[^0-9]|^E/i, "");

		return newValor;
	}

	function formatCadena(cadena) {
		let newValor = cadena.replace(/[^A-Za-z0-9 ñ]/, "");

		return newValor;
	}
	//------------------------------------------------------------------



	const handleSubmit = (e) => {

		e.preventDefault();

		if (correoContacto === '' || nombreContacto === '' || apellidoContacto === '' || celularContacto === '') {
			alert("Debe llenar los datos para contactar.");
			return;
		}

		//cuando se contacta desactiva validación
		setVisibleDatosContacto(false);
		setTipoBotton('button');
		registrointeresado();
		setShow(true);
		setCorreoContacto('');
		setNombreContacto('');
		setApellidoContacto('');
		setCelular('');
	}


	//Redirigir a las redes sociales del agente si es que tiene
	function handleClick(tipo) {
		if (tipo === 1 && bvisibleDatosContacto === false) {
			if (megusta === false) {
				setMegusta(true);
			}
			else {
				setMegusta(false);
			}
		}
		else if (tipo === 'whatsapp'/*  && bvisibleDatosContacto === false */) {
			if (celular !== 'null') {
				window.open("https://wa.me/+591" + celular, "_blank");
			} else {
				alert("El agente no cuenta con numero de celular asociado");
			}

		}
		else if (tipo === 'instagram'/*  && bvisibleDatosContacto === false */) {
			if (vinstagram !== '') {
				window.open(vinstagram, "_blank");
			} else {
				alert("El agente no cuenta con esta red social activa");
			}
		}
		else if (tipo === 'tiktok'/*  && bvisibleDatosContacto === false */) {
			if (vtiktok !== '') {
				window.open(vtiktok, "_blank");
			} else {
				alert("El agente no cuenta con esta red social activa");
			}

		}
		else if (tipo === 'facebook'/*  && bvisibleDatosContacto === false */) {
			if (vfacebook !== '') {
				window.open(vfacebook, "_blank");
			} else {
				alert("El agente no cuenta con esta red social activa");
			}

		}
	}
	useEffect(() => {
		runEffect();
		fetchApi();
		window.scroll(0, 1);
	}, [])
	const handleClick1 = (e) => {
		const slider = document.querySelector('.slider');
		slider.scrollLeft += 1380

	}




	const handleClick2 = (e) => {
		const slider = document.querySelector('.slider');
		slider.scrollLeft -= 1380

	}

	return (

		<>
			<Modal centered={true} show={show} onHide={handleClose}>
				<Modal.Header style={{ border: 'none' }} closeButton>
				</Modal.Header>
				<Modal.Body>
					<Container style={{ paddingBottom: 'calc(30px + 2vmin)' }}>
						<Row>
							<Col sm={2}>
							</Col>
							<Col sm={8}>
								<Row>
									<Col sm={12}>
										<p style={{ fontSize: '18pt', color: 'black', textAlign: 'center' }}><b>Tus datos fueron enviados exitosamente</b></p>
									</Col>
								</Row>
								<Row>
									<Col sm={12}>
										<p style={{ fontSize: '15pt', color: 'black', textAlign: 'center' }}>El agente se contactará contigo</p>
									</Col>
								</Row>
								<Row style={{ height: 'calc(130px + 2vmin)', borderRadius: '10px', background: '#FFE521' }}>
									<Col sm={3} md={12}>
										<Container>
											<Row>
												<Col sm={4} style={{ paddingLeft: '0' }}>
													<img src={((sFotoPerfilAgenteSeleccionado === null || sFotoPerfilAgenteSeleccionado === '' || sFotoPerfilAgenteSeleccionado === 'null' || sFotoPerfilAgenteSeleccionado === undefined) ? imgVendedor : sFotoPerfilAgenteSeleccionado)} style={{ float: 'left', width: '100px', height: '110px', marginTop: '20px', borderRadius: '10px' }} alt='perfilAgente'></img>
												</Col>
												<Col sm={8}>
													<div style={{ height: '50px', width: '100%', marginTop: '40px', paddingLeft: '10px' }}>
														<p style={{ fontSize: '10pt', margin: '0 0 0 0' }}><b>{sNombreAgenteSeleccionado}</b></p>
													</div>
												</Col>
											</Row>
										</Container>

									</Col>
								</Row>
							</Col>
							<Col sm={2}>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
			</Modal>
			<Container style={{ padding: '0 0 0 0' }}>
				<Row className=' justify-content-center'>
					<Col sm={9}>
						<div id='perfilagente'>
							<div className='divImagenAgente'>
								<img src={((sFotoPerfilAgenteSeleccionado === null || sFotoPerfilAgenteSeleccionado === '' || sFotoPerfilAgenteSeleccionado === 'null' || sFotoPerfilAgenteSeleccionado === undefined) ? imgVendedor : sFotoPerfilAgenteSeleccionado)} className='ImagenAgente' alt='perfilagente'></img>
							</div>
							<p className='TituloAgente'><b>Agente</b></p>
							<p className='divAgenteTopNew2'>{sNombreAgenteSeleccionado}</p>
							<p className='InfoCantInmuebles'><b>{sCantidadAnuncios} inmuebles en lista.</b></p>
						</div>
					</Col>
				</Row>

				<Row>
					<Col sm={12}>
						<div class="divBotonesRedes justify-content-center">
							<button type={tipobotton} className='BotonesRedes' style={{ backgroundImage: 'url(' + instagram + ')' }} onClick={() => handleClick('instagram')}></button>
							<button type={tipobotton} className='BotonesRedes' style={{ backgroundImage: 'url(' + tiktok + ')' }} onClick={() => handleClick('tiktok')}></button>
							<button type={tipobotton} className='BotonesRedes' style={{ backgroundImage: 'url(' + facebook + ')' }} onClick={() => handleClick('facebook')}></button>
						</div>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<div className=' justify-content-center'>
							<button type={tipobotton} className='ImgWspAgente' style={{ backgroundImage: 'url(' + wsp2 + ')' }} onClick={() => handleClick('whatsapp')}></button>
						</div>
					</Col>
				</Row>

				<br></br>
				<Row className=' justify-content-center'>
					<Col sm={6} style={{ padding: '0 0 0 0' }}>
						<Container fluid style={{ padding: '0 0 0 0' }}>
							<Row className='TituloSobreMi'>
								<Col sm={12}>
									<p style={{ fontSize: '13pt' }}><b>Sobre mi</b></p>
								</Col>
								<Col sm={12} style={{ padding: '0 20px 0 20px' }}>
									<p style={{ textAlign: 'justify', fontSize: '8pt' }}>{descripcion}</p>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
				<Row className='justify-content-center'>
					<Col sm={5} >
						<CardAgentesDetalle Id='25' VideoUrl='https://www.youtube.com/watch?v=j9XHfuGM1do' Nombre='Juan Perez' Visible='visible'></CardAgentesDetalle>
					</Col>
				</Row>
				<Row className=' justify-content-center'>
					<Col sm={5} >
						<Container style={{ padding: '0 0 0 0' }}>

							<Row className='FormContactarAgente'>
								<Col sm={12}>
									<h7 style={{ textAlign: 'left' }}>Contactar a {sNombreAgenteSeleccionado}</h7>
								</Col>
								<Col sm={12} style={{ textAlign: 'left', fontSize: '9pt' }}>
									<Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
										<Form.Label>Email</Form.Label>
										<Form.Control
											type="email"
											value={correoContacto}
											onChange={(e) => setCorreoContacto(e.target.value)}
											required={bvisibleDatosContacto}
											style={{ fontSize: '9pt' }}
											Name='Email'
										/>
									</Form.Group>
								</Col>
								<Col sm={12} style={{ textAlign: 'left', fontSize: '9pt' }}>
									<Form.Group className="sm-3" controlId="exampleForm.ControlInput2">
										<Form.Label>Nombre</Form.Label>
										<Form.Control
											type="text"
											value={nombreContacto}
											onChange={(e) => setNombreContacto(formatCadena(e.target.value))}
											required={bvisibleDatosContacto}
											style={{ fontSize: '9pt' }}
											Name='Nombre'
										/>
									</Form.Group>
								</Col>
								<Col sm={12} style={{ textAlign: 'left', fontSize: '9pt' }}>
									<Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
										<Form.Label>Apellido</Form.Label>
										<Form.Control
											type="text"
											value={apellidoContacto}
											onChange={(e) => setApellidoContacto(formatCadena(e.target.value))}
											required={bvisibleDatosContacto}
											style={{ fontSize: '9pt' }}
											Name='Apellido'
										/>
									</Form.Group>
								</Col>
								<Col sm={12} style={{ textAlign: 'left', fontSize: '9pt' }}>
									<Form.Group className="sm-3" controlId="exampleForm.ControlInput1">
										<Form.Label>Celular</Form.Label>
										<Form.Control
											type="text"
											min={8}
											value={celularContacto}
											onChange={(e) => setCelularContacto(soloNumeros(e.target.value))}
											required={bvisibleDatosContacto}
											style={{ fontSize: '9pt' }}
											Name='Celular'
										/>
									</Form.Group>
								</Col>
								<Col sm={12} style={{ textAlign: 'center', fontSize: '10pt' }}>
									<br></br>
									<center><button type='submit' style={{ fontSize: '11pt', width: '100%', height: '40px', border: 'none', backgroundColor: '#FFE521', borderRadius: '10px' }}><b>Contactar</b></button></center>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
				
				<br></br>
				<Row>
					{/* <Col sm={2}>
					</Col> */}
					<Col xs={12} sm={12}>
						<p style={{ fontSize: '12pt', margin: '0 0 15px 5px' }}><b>Inmuebles a la venta</b></p>
						<br></br>
						{isLoading ? <LoadingSpinner /> :
							<ul className='listaAnunciosf2' style={{ listStyle: 'none' }}>
								{!anunciosagentes ? <p style={{ fontSize: '10pt', padding: '0 0 0 0' }}>Sin inmuebles que mostrar</p> : anunciosagentes.map((item, index) => {
									return (<>

										<li key={index} >
											<CardAnunciosAgente Id={index} direccion={item.Direccion} Precio={item.Precio} TipoInmueble={item.TipoInmueble} ListaImagenes={item.Imagenes} VisibleDestacar={item.Destacado} terreno={item.terreno} construccion={item.construccion} dormitorios={item.dormitorios} banios={item.banios} parqueos={item.parqueos} CodigoAnuncio={item.idAnuncio} Latitud={item.Latitud} Longitud={item.Longitud} Descripcion={item.Descripcion} Transaccion={item.Transaccion} Titulo={item.Titulo} Nombre={item.Nombre} ApellidoPaterno={item.ApellidoPaterno} Celular={item.celular} EstadoProyecto={item.EstadoProyecto} FechaEntrega={item.FechaEntregaProyecto}></CardAnunciosAgente>
										</li>

									</>
									)
								})}
							</ul>
						}
					</Col>
					{/* <Col sm={2}>
					</Col> */}
				</Row>
				<Row>
					<Col sm={12}>
						<p style={{ fontSize: '12pt', margin: '10px 0 15px 5px' }}><b>Mis Videos</b></p>
						<br></br>
						<div className='DivAgentes'>
							<ul id='topmerkasa'>
								<Container>
									<Row style={{ gap: '2%', rowGap: '2%', justifyContent:'center' }}>
										{!videosagentes ? <p style={{ fontSize: '9pt' }}>Sin videos que mostrar</p> : videosagentes.map((item) => {
											return (

												<>

													<Col style={{ padding: '0 0 0 10px', width: '30%' }}>
														<li className='topAgenteDetalle'>
															<ReactPlayer
																url={item.urlVideo}
																className='react-player'
																width='100%'
																playing={false}
																height='calc(9em + 9vw)'
																controls={true}
															/></li>
													</Col>
												</>

											)
										})
										}
									</Row>
								</Container>
							</ul>
						</div>

					</Col>
				</Row>
				<Row>
					<Col sm={2}>
					</Col>
				</Row>
				<br></br>
				<br></br>

				<Row>
					<Col sm={12} >
						<p style={{ fontSize: '12pt', margin: '0 0 15px 5px' }}><b>Mis Fotos</b></p>
						<br></br>
						<br></br>
						{isLoading ? <LoadingSpinner /> :
							<>
								<ul className='fotos-agentes justify-content-center'>
									{!fotos ? <p style={{ padding: '0 0 0 0', margin: '0 0 0 0', fontSize: '9pt' }}>Sin fotos que mostrar</p> : fotos.map((item, index) => {
										return (<><li id={item.id} style={{ listStyle: 'none' }} className='topAgenteDetalle' index={index}>
											<Canvas src={item.UrlImagen} width={item.width} height={item.height} index={item.id}></Canvas>
										</li></>
										)
									})
									}
								</ul>

							</>
						}
					</Col>
				</Row>
			</Container>

		</>
	)

}
export default Perfilagente;
