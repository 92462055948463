import React, { useState, useEffect } from "react";
import axios from "axios"; // npm i axios
import CardAgentes from "./CardAgentes";
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "./LoadingSpinner";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

function TopAgentes() {
	let itop = 0;

	const [items2, setItems2] = useState();
	const [counter, setCounter] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isVisible, setIsVisble] = useState("hidden");

	async function fetchApi(top) {
		setIsLoading(true);
		let fd = new FormData();
		fd.append("top", top);

		const res = await axios.post(
			"https://merkasa.online/api/ApiListarAgentes.php",
			fd
		);
		if (res.data.items.length > 0) {

			let videosAgentes = res.data.items 
			setItems2(videosAgentes);
			setIsVisble(res.data.items[0].visible);
			setIsLoading(false);
			}
	}

	useEffect(() => {
		fetchApi(itop);
	}, []);

	const handleClick1 = (e) => {
		const slider = document.querySelector(".slider-agentes");

		window.innerWidth > 700
			? (slider.scrollLeft += 1240)
			: (slider.scrollLeft += 390);
	};

	const handleClick2 = (e) => {
		const slider = document.querySelector(".slider-agentes");
		window.innerWidth > 700
			? (slider.scrollLeft -= 1240)
			: (slider.scrollLeft -= 390);
	};

	return (
		<>
			{/* <div style={{ height: "auto" }}> */}
			<div
				className="d-flex justify-content-center align-items-center px-2"
				style={{ height: "auto" }}
			>
				{isLoading ? (
					<p>¡Se el primero en promocionarte!</p>
				) : (
					<>
						{/* <i
			  onClick={(e) => handleClick2(e)}
			  class="arrow left-agente"
			  disabled={isLoading}
			  style={{ visibility: isVisible }}
			></i> */}
			<MdArrowBackIos
					className="flex-shrink-0 d-none d-sm-block"
					style={{
						fontSize: "32px",
						cursor: "pointer",
						visibility: isVisible,
					}}
					disabled={isLoading}
					onClick={(e) => handleClick2(e)}
				/>
				<ul className="slider-agentes">
					{!items2
					? "Cargando Top Agentes...."
							: items2.map((item, index) => {
								//if(index <=2){ }

								return (
									<li
										key={index}
										className={`slide-agente d-flex justify-content-center mx-2 ${
											index === 0 ? "ms-0" : ""
										} ${index === items2.length - 1 ? "me-0" : ""}`}
										style={{ listStyle: "none" }}
									>
										<CardAgentes
											Id={item.idAgente}
											VideoUrl={item.videoUrl}
											Nombre={
												item.Nombre +
												" " +
												item.ApellidoPaterno +
												" " +
												item.ApellidoMaterno
											}
											Visible={item.visible}
											CantAnuncios={item.CantAnuncios}
											Descripcion={item.descripcion}
											Foto={item.fotoperfil}
											Facebook={item.urlFacebook}
											Instagram={item.urlInstagram}
											Tiktok={item.urlTikTok}
											NumeroCelular={item.Celular}
										></CardAgentes>
									</li>
								);
							})}
						</ul>
						{/* <i
			  onClick={(e) => handleClick1(e)}
			  class="arrow right-agente"
			  disabled={isLoading}
			  style={{ visibility: isVisible }}
			></i> */}
			<MdArrowForwardIos
					className="flex-shrink-0 d-none d-sm-block"
					style={{
						fontSize: "32px",
						cursor: "pointer",
						visibility: isVisible,
					}}
					disabled={isLoading}
					onClick={(e) => handleClick1(e)}
				/>
		</>
				)}
			</div>
		</>
		);
}

export default TopAgentes;
