import React, { useState, useEffect, useRef } from "react";
import axios from "axios"; // npm i axios
import CardAnuncios from "./CardAnuncios";
import "react-slideshow-image/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

function TopMerKasa() {
  let itop = 0;

  const [items2, setItems2] = useState();
  const [listaFavoritos, setListaFavoritos] = useState([]);

  async function fetchApi(top) {
    if (localStorage.length > 1) {
      let idusuario = localStorage.getItem("idusuario");
      let f = new FormData();
      f.append("idusuario", idusuario);
      const resf = await axios.post(
        "https://merkasa.online/api/ApiListarAnunciosUsuarioFavoritos.php",f);
      setListaFavoritos(resf.data.items);
    }

    let fd = new FormData();
    fd.append("top", top);
    const res = await axios.post(
      "https://merkasa.online/api/ApiListarAnuncios.php",
      fd
    );
    setItems2(res.data.items);
  }

  const navigate = useNavigate();

  const handleRedirect = (idAnuncio, Latitud, Longitud) => {
    sessionStorage.setItem(
      "CodAnuncioActualSeleccion",
      JSON.stringify(idAnuncio)
    );
    sessionStorage.setItem("LatitudActualSeleccion", JSON.stringify(Latitud));
    sessionStorage.setItem("LongitudActualSeleccion", JSON.stringify(Longitud));
    navigate("/DetalleAnuncio");
  };

  useEffect(() => {
    fetchApi(itop);
  }, []);

  const handleClick1 = (e) => {
    const slider = document.querySelector(".slider");
    slider.scrollLeft += 1380;
  };

  const handleClick2 = (e) => {
    const slider = document.querySelector(".slider");
    slider.scrollLeft -= 1380;
  };

  return (
    <>
      <div style={{ position: "relative", top: "0", paddingBottom: "20px" }}>
        <center>
          <p style={{ fontSize: "26pt", whiteSpace: "nowrap" }}>
            Los más <b>destacados</b>
          </p>
        </center>
      </div>

      <div
        className="d-flex justify-content-center align-items-center w-100 p-2"
        style={{ height: "500px" }}
      >
        {/* <i onClick={(e) => handleClick2(e)} class="arrow left"></i> */}
        <MdArrowBackIos
          className="flex-shrink-0 d-none d-sm-block"
          style={{
            fontSize: "32px",
            cursor: "pointer",
          }}
          onClick={(e) => handleClick2(e)}
        />
        <ul className="slider">
          {!items2
            ? "Cargando Top Merkasa...."
            : items2.map((item, index) => {
                /*onClick={(e) => handleRedirect(item.idAnuncio,item.Latitud, item.Longitud)}*/
                return (
                  <li
                    key={index}
                    style={{ listStyle: "none" }}
                  >
                    <CardAnuncios
                      Id={index}
                      direccion={item.Direccion}
                      Precio={item.Precio}
                      ListaImagenes={item.Imagenes}
                      VisibleDestacar={item.Destacado}
                      terreno={item.terreno}
                      construccion={item.construccion}
                      dormitorios={item.dormitorios}
                      banios={item.banios}
                      parqueos={item.parqueos}
                      CodigoAnuncio={item.idAnuncio}
                      Transaccion={item.Transaccion}
                      Nombre={item.Nombre}
                      ApellidoPaterno={item.ApellidoPaterno}
                      Celular={item.celular}
                      FecUsuCreacion={item.FecUsuCreacion}
                      Foto={item.foto}
                      AnioConstruccion={item.anioconstruccion}
                      TipoInmueble={item.TipoInmueble}
                      Favoritos={listaFavoritos}
					  Moneda = {item.moneda}
                    ></CardAnuncios>
                  </li>
                );
              })}
        </ul>
        {/* <i onClick={(e) => handleClick1(e)} class="arrow right"></i> */}
        <MdArrowForwardIos
          className="flex-shrink-0 d-none d-sm-block"
          style={{
            fontSize: "32px",
            cursor: "pointer",
          }}
          onClick={(e) => handleClick1(e)}
        />
      </div>
    </>
  );
}

export default TopMerKasa;
