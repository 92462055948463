import React, { useState, useEffect } from "react";
import axios from "axios"; // npm i axios
import "react-slideshow-image/dist/styles.css";
import LoadingSpinner from "./LoadingSpinner";
import CardAnunciosProyectos from "./CardAnunciosProyectos";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

function TopProyectos() {
	let itop = 0;

	const [items2, setItems2] = useState();
	const [counter, setCounter] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isVisible, setIsVisble] = useState("");
	const [listaFavoritos, setListaFavoritos] = useState([]);

	async function fetchApi(top) {
		if (localStorage.length > 1) {
			let idusuario = localStorage.getItem("idusuario");
			let f = new FormData();
			f.append("idusuario", idusuario);
			const resf = await axios.post(
				"https://merkasa.online/api/ApiListarAnunciosUsuarioFavoritos.php",
				f
			);
			setListaFavoritos(resf.data.items);
		}

		setIsLoading(true);
		let fd = new FormData();
		fd.append("top", top);

		const res = await axios.post(
			"https://merkasa.online/api/ApiListarProyectos.php",
			fd
		);
		if (res.data.items.length > 0){
			setItems2(res.data.items);
			console.log("proyectos",res.data.items);
			setIsVisble(res.data.items[0].visible);
			setIsLoading(false);
			}  }

	useEffect(() => {
		fetchApi(itop);
	}, []);

	const handleClick1 = (e) => {
		const slider = document.querySelector(".slider-project");
		slider.scrollLeft += 1380;
	};

	const handleClick2 = (e) => {
		const slider = document.querySelector(".slider-project");
		slider.scrollLeft -= 1380;
	};

	return (
		<>
			<div className="d-flex justify-content-center align-items-center h-100 px-2">
				{isLoading ? (
					<p>¡Sé el primero en publicar!</p>
				) : (
					<>
						{/* <i onClick={(e) => handleClick2(e)} class="arrow left" disabled= {isLoading} style={{visibility:isVisible}}></i> */}
						<MdArrowBackIos
							className="flex-shrink-0 d-none d-sm-block"
							style={{
								fontSize: "32px",
								cursor: "pointer",
								visibility: isVisible,
							}}
							disabled={isLoading}
							onClick={(e) => handleClick2(e)}
						/>
						<ul id="topmerkasanew" className="slider-project">
							{!items2
							? "Cargando Top Merkasa...."
									: items2.map((item, index) => {
										return (
											<li
												key={index}
												className="slide-project"
												style={{ listStyle: "none" }}
											>
												<CardAnunciosProyectos
													Id={index}
													Title={item.Direccion}
													Precio={item.Precio}
													ListaImagenes={item.Imagenes}
													VisibleDestacar={item.Destacado}
													terreno={item.terreno}
													construccion={item.construccion}
													dormitorios={item.dormitorios}
													banios={item.banios}
													parqueos={item.parqueos}
													CodigoAnuncio={item.idAnuncio}
													Latitud={item.Latitud}
													Longitud={item.Longitud}
													Descripcion={item.Descripcion}
													EstadoProyecto={item.EstadoProyecto}
													FechaEntrega={item.FechaEntregaProyecto}
													Transaccion={item.Transaccion}
													Titulo={item.Titulo}
													Nombre={item.Nombre}
													ApellidoPaterno={item.ApellidoPaterno}
													Celular={item.celular}
													Favoritos={listaFavoritos}
													Moneda = {item.moneda}
												></CardAnunciosProyectos>
											</li>
										);
									})}
								</ul>
								{/* <i
			  onClick={(e) => handleClick1(e)}
			  class="arrow right"
			  disabled={isLoading}
			  style={{ visibility: isVisible }}
			></i> */}
			<MdArrowForwardIos
					className="flex-shrink-0 d-none d-sm-block"
					style={{
						fontSize: "32px",
						cursor: "pointer",
						visibility: isVisible,
					}}
					disabled={isLoading}
					onClick={(e) => handleClick1(e)}
				/>
		</>
				)}
			</div>
		</>
		);
}

export default TopProyectos;
